<div class="card mb-4">
    <div class="row p-4">
      <div class="col">
        <mat-form-field class="white-label">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label >Search</mat-label>
          <input placeholder="Search by email or wallet address"  matInput [(ngModel)]="searchValue" value="">
        </mat-form-field>
      </div>
      <div class="col-3">
        <button type="button" mat-raised-button color="primary" [disabled]="isLoading" (click)="fetchUserRegsitrations()">Search <span class="rainbow-border"></span></button>
        <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base m-3"><img (click)="saveData()" src="../../assets/svg/download-solid.svg"/></button>
      </div>
    </div>
  </div>

  <div class="card">
    <form [formGroup]="filterForm" class="p-4">
        <p >Filter by:</p>
        <div class="row p-3">
            <div class="col-2">
                <mat-form-field>
                    <mat-label>Tier</mat-label>
                    <mat-select formControlName="tier">
                        <mat-option selected [value]="-2">Show All</mat-option>
                        <mat-option *ngFor="let tier of tiers" [value]="tier.index">
                            {{tier.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="dateFrom" placeholder="Start date">
                        <input matEndDate formControlName="dateTo" placeholder="End date">
                    </mat-date-range-input>

                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

            </div>
            <div class="col-2">
                <mat-form-field>
                    <mat-label>KYC</mat-label>
                    <mat-select formControlName="kycApproved">
                        <mat-option [value]="'all'">Show All</mat-option>
                        <mat-option [value]="true">Approved</mat-option>
                        <mat-option [value]="false">Unappproved</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Deals Joined</mat-label>
                    <input formControlName="dealsJoined" matInput type="number">
                </mat-form-field>
            </div>
            <div class="col-2">
                <mat-form-field>
                    <mat-label>Referred</mat-label>
                    <mat-select formControlName="isReferred">
                        <mat-option [value]="'all'">Show All</mat-option>
                        <mat-option selected [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <button (click)="fetchUserRegsitrations()" type="button" mat-raised-button color="primary" class="w-100" [disabled]="isLoading">
                    <mat-icon>search</mat-icon>
                    Apply Filter
                  <span class="rainbow-border"></span>
                </button>
            </div>
        </div>
    </form>
</div>

<div class="card mt-3">
    <table class="table table-responsive" matSort (matSortChange)="sortData($event)" [matSortActive]="sortByColumn"
        [matSortDirection]="sortDirection">
        <thead>
            <tr>
                <th>Registration</th>
                <th mat-sort-header="email">Email</th>
                <th>KYC</th>
                <th>Referred</th>
                <th>Wallet</th>
            </tr>
        </thead>
        <tbody [ngClass]="{'opacity-50': isLoading}">
            <tr *ngFor="let user of userRegistrations">
                <td><img [src]="user.tierIndex | imgTierPipe" class="tier-image"/> {{ user.creationDateTime }}</td>
                <td>{{user.email}}</td>
                <td>
                    <span *ngIf="user.kycConfirmed"><mat-icon class="bg-success rounded">check</mat-icon> Passed</span>

                    <span *ngIf="!user.kycConfirmed"><mat-icon class="bg-ash rounded">cancel</mat-icon> Not passed</span>
                </td>
                <td>
                    <mat-icon *ngIf="user.referrer" class="bg-success rounded">check</mat-icon>
                    <mat-icon *ngIf="!user.referrer" class="bg-ash rounded">cancel</mat-icon>
                </td>
                <td>
                    <span>{{user.ethAddress}}</span>
                    <a mat-icon-button class="m-3" matTooltip="Address Book" matTooltipPosition="above"
                        (click)="navigateToUserDetails(user.ethAddress)">
                        <span class="icomoon-address-book"></span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="card mt-3">
    <mat-paginator [length]="totalCount"
                       [pageIndex]="page"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="pageChanged($event)"
                       aria-label="Select page">
        </mat-paginator>
</div>
