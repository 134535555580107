import {Component, OnInit, ViewChild} from '@angular/core';
import {ChangeWalletServiceProxy, IUserKYCSync, UsersServiceProxy} from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { Router } from '@angular/router';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DatePipe } from '@angular/common';
import { Observable } from "rxjs";
import {Web3Service} from "../../shared/web3-service";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-change-wallet',
  templateUrl: './change-wallet.component.html',
  styleUrls: ['./change-wallet.component.scss'],
})
export class ChangeWalletComponent extends ComponentBase implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private changeWalletService: ChangeWalletServiceProxy,
    private usersServiceProxy: UsersServiceProxy,
    private router: Router,
    private userSessionProvider: UserSessionProvider,
    private datePipe: DatePipe,
    public web3Service: Web3Service,
    private paginatorIntl: MatPaginatorIntl
  ) {
    super();
  }

  public wallets: any = [];
  pageSlice: any
  pageSize: number = 6;

  searchText: string = ''
  isVisible: boolean = false

  async ngOnInit(): Promise<void> {
    this.paginatorIntl.itemsPerPageLabel = 'Show'
    await this.changeWalletService.getAllWallets().toPromise().then((res) => {
      this.wallets = res.filter((wallet: any) => wallet.status < 3);
      this.pageSlice = this.wallets.slice(0, 6);
    });
    await this.updateWalletsKYCStatus();
  }

  async updateWalletsKYCStatus(): Promise<void> {
    for (let i = 0; i < this.pageSlice.length; i++) {
      const wallet = this.pageSlice[i];
      const kycResult = await this.kycCheck(wallet.toAddress).toPromise();
      this.pageSlice[i].kycConfirmed = kycResult.kycConfirmed;
    }
  }

  kycCheck(address: string): Observable<IUserKYCSync> {
    return this.usersServiceProxy.syncKYC(address)
  }

  async searchWallet() {
    if (!this.searchText && !this.isVisible) {
      await this.changeWalletService.getAllWallets().toPromise().then((res) => {
        this.wallets = res.filter((wallet: any) => wallet.status < 3)
        this.pageSlice = this.wallets.slice(0, 6);
      });
      await this.updateWalletsKYCStatus();
      return
    }
    this.wallets = await this.changeWalletService.getAllWallets(this.searchText).toPromise();
    this.pageSlice = this.wallets.slice(0, 6);
    await this.updateWalletsKYCStatus();
  }

  async filterWallets() {
    if (this.isVisible) {
      await this.changeWalletService.getAllWallets().toPromise().then((res) => {
        this.wallets = res.filter((wallet: any) => wallet.status <= 3)
        this.pageSlice = this.wallets.slice(0, 6);
      });
      await this.updateWalletsKYCStatus();
    }else {
      await this.changeWalletService.getAllWallets().toPromise().then((res) => {
        this.wallets = res.filter((wallet: any) => wallet.status < 3)
        this.pageSlice = this.wallets.slice(0, 6);
      });
      await this.updateWalletsKYCStatus();
    }
  }

  transferTier(walletInfo: any) {
    const changeRequests = [
      this.web3Service.changeWalletTierCalculator(this.userSessionProvider.linkedWallet, walletInfo.fromAddress, walletInfo.toAddress),
      this.web3Service.changeWalletLocker(this.userSessionProvider.linkedWallet, walletInfo.fromAddress, walletInfo.toAddress),
      this.web3Service.changeWalletStakingPool(this.userSessionProvider.linkedWallet, walletInfo.fromAddress, walletInfo.toAddress)
    ];

    Promise.all(changeRequests)
      .then(() => {
        this.changeWalletService.setWalletChangeStatus(walletInfo.id, 3).toPromise().then(async () => {
          await this.changeWalletService.getAllWallets().toPromise().then((res) => {
            this.wallets = res.filter((wallet: any) => wallet.status < 3)
            this.pageSlice = this.wallets.slice(0, 6);
          });
          await this.updateWalletsKYCStatus();
        });
      })
      .catch((error) => {
        console.error("Error in one of the change requests:", error);
      });
  }

  onPageChange(event?: PageEvent) {
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.wallets.length) {
      endIndex = this.wallets.length
    }
    this.pageSlice = this.wallets.slice(startIndex, endIndex);
  }
}
