<main class="dashboard-main">
  <section class="deals-section p-40">

    <div class="__deals-list">
      <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

      <div class="card not-found" *ngIf="!waitingDeals && deals.length == 0">
        <div class="icon icomoon-box"></div>
        <div class="not-found__text">No deals yet</div>
      </div>

      <div class="card-list per-row-2">
        <div class="deal-card card" *ngFor="let item of deals">

          <div class="card-content">
            <div class="d-flex align-it align-items-center justify-content-between">
              <div class="token-name token-name-inline">
                <div class="token-title">{{item.name}}</div>
              </div>

              <div class="action-btns">
                <button mat-icon-button
                        (click)="navigateToEditDeal(item.id)"
                        matTooltip="Edit Deal - {{item.name}}"
                        matTooltipPosition="above">
                  <span class="icomoon-edit"></span>
                </button>

                <button mat-icon-button
                        (click)="showWarningDeleteModal(item.id)"
                        matTooltip="Delete Deal - {{item.name}}"
                        matTooltipPosition="above">
                  <img src="assets/images/svg/bin.svg" alt="bin" class="icon-bin">
                </button>
              </div>

            </div>

            <div class="deal-card__info">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="caption text-muted">Deadline</div>
                  <div>{{item.deadline | date}}</div>
                </div>
                <div class="col-lg-6">
                  <div class="caption text-muted">Investment URL</div>
                  <div class="one-line"><a target="_blank" href="{{item.investmentURL}}">{{item.investmentURL}}</a></div>
                </div>
                <div class="col-lg-6">
                  <div class="caption text-muted">Img URL</div>
                  <div class="one-line"><img class="logo_img" src="{{item.imgUrl}}" /></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-6 col-md-12">
                <div class="caption text-muted">Summary</div>
                <div class="one-line" [innerHTML]="item.summary"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</main>
