import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import { Router } from '@angular/router';
import { PagedList } from 'src/models/paged-list';
import { AnalyticsServiceProxy, TierUsersDTO, UserTierChangeDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';

@Component({
  selector: 'app-analytics-tier-changes',
  templateUrl: './analytics-tier-changes.component.html',
  styleUrls: ['./analytics-tier-changes.component.scss']
})
export class AnalyticsTierChangesComponent extends ComponentBase implements OnInit {
  @Input() selectedSubComponent: 'tier_upgrade' | 'tier_downgrade' | 'new_tier_members' | 'tiers_lost';

  isLoading: boolean = false;
  tierChanges: UserTierChangeDTO[];
  filterForm: FormGroup;
  selectedTier: string;
  sortByColumn: string = '';
  sortDirection: SortDirection = '';
  searchValue: string = '';

  totalCount: number | null = null;
  pageSize: number = 25;
  page: number = 0;

  tiers: { index: number, name: string }[] = [
    {index: -1, name: 'None'},
    {index: 0, name: 'Baby Paca'},
    {index: 1, name: 'Little Paca'},
    {index: 2, name: 'Chief Paca'},
    {index: 3, name: 'Elder Paca'},
    {index: 4, name: 'Alpha Paca'}
  ];
  constructor(private formBuilder: FormBuilder, private router: Router, private analyticsServiceProxy: AnalyticsServiceProxy) {
    super();
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      previousTier: -2,
      currentTier: -2,
      dateFrom: this.getTwoMonthsBehind,
      dateTo: new Date(),
    });

    if(this.selectedSubComponent == 'new_tier_members'){
      this.filterForm.get('previousTier').setValue(-1);
      this.filterForm.get('currentTier').setValue(-2);
    }
    if(this.selectedSubComponent == 'tiers_lost'){
      this.filterForm.get('previousTier').setValue(-2);
      this.filterForm.get('currentTier').setValue(-1);
    }

    this.fetchUserTierChanges()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedSubComponent){
      this.searchValue = '';
      if(this.selectedSubComponent == 'new_tier_members'){
        this.filterForm.get('previousTier').setValue(-1);
        this.filterForm.get('currentTier').setValue(-2);
      }
      if(this.selectedSubComponent == 'tiers_lost'){
        this.filterForm.get('previousTier').setValue(-2);
        this.filterForm.get('currentTier').setValue(-1);
      }

      this.fetchUserTierChanges();
    }
  }

  fetchUserTierChanges(){
    this.isLoading = true;

    this.analyticsServiceProxy.GetUserTierChanges(this.searchValue, this.filterForm.get('previousTier').value, this.filterForm.get('currentTier').value, this.filterForm.get('dateFrom').value,
    this.filterForm.get('dateTo').value, this.pageSize, this.page)
      .subscribe(result => {
        this.tierChanges = result.items;
        this.totalCount = result.totalItems;
        this.isLoading = false;
      },
        error => {
          this.showErrorModal(error.message || JSON.parse(error.response).message);
        }, () => {
          this.isLoading = false;
        });
  }

  pageChanged($event: any): void {
    this.page = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.fetchUserTierChanges();
  }

  get getTwoMonthsBehind() {
    const twoMonthsInMs = 1000 * 60 * 60 * 24 * 30 * 2; // Milliseconds in 2 months
    const newDate = new Date(new Date().getTime() - twoMonthsInMs);
    newDate.setDate(Math.min(newDate.getDate(), newDate.getMonth() === 0 ? 28 : 31)); // Handle edge cases for month length
    return newDate;
  }

  sortData(sort: Sort) {
  }

  public async saveData(): Promise<void> {
    const allData = await this.analyticsServiceProxy.GetUserTierChanges(this.searchValue ,this.filterForm.get('previousTier').value, this.filterForm.get('currentTier').value, this.filterForm.get('dateFrom').value,
    this.filterForm.get('dateTo').value, this.pageSize, this.page).toPromise();

    const csvString = this.convertObjectToCSV(allData.items);
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'tier_changes.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv;
  }
  formatDate(date: Date): string {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var convertedDate = new Date(date);
    return `${days[convertedDate.getDay()]} ${months[convertedDate.getMonth()]} ${convertedDate.getDate()} ${convertedDate.getFullYear()}`;
  }

  formatTimeToGMT(date: Date): string {
    var convertedDate = new Date(date);
    const hours = convertedDate.getHours().toString().padStart(2, '0');
    const minutes = convertedDate.getMinutes().toString().padStart(2, '0');
    const seconds = convertedDate.getSeconds().toString().padStart(2, '0');

    const gmtOffset = convertedDate.getTimezoneOffset() / 60; // Get offset in hours
    const offsetSign = gmtOffset >= 0 ? '+' : '-';
    const offsetHours = Math.abs(gmtOffset).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds} GMT ${offsetSign}${offsetHours}00`;
  }

  public navigateToUserDetails(userAddress: string) {
    this.router.navigate(['/user-details'], { queryParams: { userid: userAddress } });
  }
}
