import { Component, Input } from '@angular/core';
import { Referrers } from 'src/models/referral.model';

@Component({
  selector: 'app-ref-extantion-panel-inner',
  templateUrl: './ref-extantion-panel-inner.component.html',
  styleUrls: ['./ref-extantion-panel-inner.component.scss']
})
export class RefExtantionPanelInnerComponent {
  @Input() ref!: Referrers;
}
