<div class="modal-header">
  <i class="icon icomoon-wallet"></i>
  <h5 class="modal-title">Your wallet</h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body stack">
  <button class="address-btn --copy w-100 mb-4">
    <span [cdkCopyToClipboard]="account">{{account}}</span>
  </button>

  <div class="row">
    <div class="col-md-6">
      <button mat-raised-button color="primary" class="w-100" [mat-dialog-close]="true">
        Log Out
        <span class="rainbow-border"></span>
      </button>
    </div>
    <div class="col-md-6">
      <a [href]="account | blockExplorerAddressPipe: web3ChainId"
         mat-raised-button color="primary" class="w-100"
         target="_blank">
        View BSCScan
        <span class="rainbow-border"></span>
      </a>
    </div>
  </div>

</div>
