export default [{
  name: "ETH",
  chainId: 1,
  chainIdHex: "0x1",
  img: "/assets/svg/eth.svg",
  imgBlack: "/assets/svg/eth_black.svg",
  imgModal: "/assets/svg/eth_modal.svg",
  networkParams:{
    chainId: "0x1",
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
    rpcUrls: ["https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/eth/mainnet"],
    blockExplorerUrls: ['https://etherscan.io']
  }
},
{
  name: "Kovan",
  chainId: 42,
  chainIdHex: "0x2a",
  img: "/assets/svg/eth.svg",
  imgBlack: "/assets/svg/eth_black.svg",
  imgModal: "/assets/svg/eth_modal.svg",
  networkParams:{
    chainId: "0x2a",
    chainName: 'Kovan Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
    rpcUrls: ["https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ['https://kovan.etherscan.io']
  }
},
{
  name: "BSC",
  chainId: 56,
  chainIdHex: "0x38",
  img: "/assets/svg/bsc.svg",
  imgBlack: "/assets/svg/bsc.svg",
  imgModal: "/assets/svg/bsc_modal.svg",
  networkParams:
  {
    chainId: "0x38",
    chainName: 'BSC Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed1.defibit.io", "https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ['https://bscscan.com/']
  }
},
{
  name: "BSC Testnet",
  chainId: 97,
  chainIdHex: "0x61",
  img: "/assets/svg/bsc.svg",
  imgBlack: "/assets/svg/bsc.svg",
  imgModal: "/assets/svg/bsc_modal.svg",
  networkParams:
  {
    chainId: "0x61",
    chainName: 'BSC Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545", "https://data-seed-prebsc-2-s1.binance.org:8545", "https://data-seed-prebsc-1-s2.binance.org:8545"],
    blockExplorerUrls: ['https://testnet.bscscan.com/']
  }
},
{
  name: "Heco Testnet",
  chainId: 256,
  chainIdHex: "0x100",
  img: "/assets/svg/heco.svg",
  imgBlack: "/assets/svg/heco.svg",
  imgModal: "/assets/svg/heco.svg",
  networkParams:
  {
    chainId: "0x100",
    chainName: 'Heco Testnet',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ["https://http-testnet.hecochain.com"],
    blockExplorerUrls: ['https://testnet.hecoinfo.com/']
  }
},
{
  name: "Polygon",
  chainId: 137,
  chainIdHex: "0x89",
  img: '/assets/images/network/polygon.svg',
  imgBlack: '/assets/images/network/polygon.svg',
  imgModal: '/assets/images/network/polygon.svg',
  networkParams:
  {
    chainId: "0x89",
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ["https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mainnet"],
    blockExplorerUrls: ['https://polygonscan.com/']
  }
},
{
  name: "Polygon Test",
  chainId: 80001,
  chainIdHex: "0x13881",
  img: '/assets/images/network/polygon.svg',
  imgBlack: '/assets/images/network/polygon.svg',
  imgModal: '/assets/images/network/polygon.svg',
  networkParams:
  {
    chainId: "0x13881",
    chainName: 'Polygon Test',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ["https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mumbai"],
    blockExplorerUrls: ['https://polygonscan.com/']
  }
},
{
  name: "Solana",
  chainId: -100,
  chainIdHex: "0x13881",
  img: '/assets/images/network/solana.svg',
  imgBlack: '/assets/images/network/solana.svg',
  imgModal: '/assets/images/network/solana.svg',
  networkParams:
  {
    chainId: "0x13881",
    chainName: 'Solana',
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 18,
    },
    rpcUrls: ["https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mumbai"],
    blockExplorerUrls: ['https://polygonscan.com/']
  }
},
{
  name: "Base",
  chainId: 8453,
  chainIdHex: "0x2105",
  img: '/assets/svg/Base.svg',
  imgBlack: '/assets/svg/Base.svg',
  imgModal: '/assets/svg/Base.svg',
  networkParams:
  {
    chainId: "0x2105",
    chainName: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ['https://basescan.org/']
  }
},
{
  name: "Base Sepolia",
  chainId: 84532,
  chainIdHex: "0x14a34",
  img: '/assets/svg/Base.svg',
  imgBlack: '/assets/svg/Base.svg',
  imgModal: '/assets/svg/Base.svg',
  networkParams:
  {
    chainId: "0x14a34",
    chainName: 'Base Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ["https://wispy-bitter-wind.base-sepolia.quiknode.pro/57b95cb7d75ce1bfb41d3aa3b326c151dbc11e89/"],
    blockExplorerUrls: ['https://sepolia.basescan.org//']
  }
}
];
