/// <reference lib="es2019.object" />
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PagedList } from 'src/models/paged-list';
import { StatisticsServiceProxy, UserDTO, UserVisit } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { UAParser } from 'ua-parser-js';
import { AllCountries } from './countries';
declare type UserWithDeviceInfo = { browser: string; os: string; device: string; date: Moment; email: string; address: string; tierIndex: number; ipAddress: string; userAgent: string; country: string; countryCode: string; countryFlagUrl: string; };

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent extends ComponentBase implements OnInit {
  account: string = '';
  loading: boolean = false;
  visitsForChart: UserWithDeviceInfo[];
  usersGroupedByCountry: { country: string; users: UserWithDeviceInfo[] }[];
  usersGroupedByDevice: { device: string; users: UserWithDeviceInfo[] }[];
  usersGroupedByOS: { os: string; users: UserWithDeviceInfo[] }[];
  usersGroupedByBrowser: { browser: string; users: UserWithDeviceInfo[] }[];
  deviceChart: any;
  osChart: any;
  browserChart: any;

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  countriesList: string[] = AllCountries;
  filteredCountryOptions: Observable<string[]>;
  colors = ['#4287f5', '#E2725B', '#FFDB58', '#E6E6FA', '#B76E79', '#0C2340'];

  userVisits: UserWithDeviceInfo[];
  tiers: { index: number; name: string }[] = [
    { index: -1, name: 'None' },
    { index: 0, name: 'Copper Bull' },
    { index: 1, name: 'Bronze Bull' },
    { index: 2, name: 'Silver Bull' },
    { index: 3, name: 'Golden Bull' },
    { index: 4, name: 'Titanium Bull' },
    { index: 5, name: 'Platinum Bull' },
  ];
  years: number[];
  selectedMonth: number;
  selectedYear: number;
  noOfCountriessToDisplay: number = 10;
  usersCategory: 'all' | 'active' | 'registered' = 'all';

  filterForm: FormGroup;
  isLoading: boolean = false;
  searchValue: string = '';
  totalCount: number | null = null;
  pageSize: number = 25;
  page: number = 0;

  constructor(
    private userSessionProvider: UserSessionProvider,
    private statisticsServiceProxy: StatisticsServiceProxy,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    super();

    let currentYear = new Date().getFullYear();
    this.years = [
      currentYear - 3,
      currentYear - 2,
      currentYear - 1,
      currentYear,
    ];
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.account = this.userSessionProvider.linkedWallet;

    this.filterForm = this.formBuilder.group({
      country: '',
      tier: -2,
      dateFrom: this.getTwoMonthsBehind,
      dateTo: new Date(),
    });

    this.filteredCountryOptions = this.filterForm
      .get('country')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );

    this.fetchStatisticsData();
    this.fetchUserRegsitrations();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countriesList.filter((option) =>
      option.toLowerCase().startsWith(filterValue)
    );
  }

  changeUsersCategory(category: 'all' | 'active' | 'registered'){
    if(category != this.usersCategory){
      this.usersCategory = category;
      this.fetchUserRegsitrations();
      this.fetchStatisticsData();
    }
  }

  get getTwoMonthsBehind() {
    const twoMonthsInMs = 1000 * 60 * 60 * 24 * 30 * 2; // Milliseconds in 2 months
    const newDate = new Date(new Date().getTime() - twoMonthsInMs);
    newDate.setDate(
      Math.min(newDate.getDate(), newDate.getMonth() === 0 ? 28 : 31)
    ); // Handle edge cases for month length
    return newDate;
  }

  filterChanged() {
    this.fetchStatisticsData();
    if(this.usersCategory == 'all'){
      this.fetchUserRegsitrations();
    }
  }

  loadMoreCountries() {
    this.noOfCountriessToDisplay += 3;
  }

  noOfCountriesInMonth(): number {
    if (!this.usersGroupedByCountry) {
      return 0;
    }
    return Object.keys(this.usersGroupedByCountry).length;
  }

  fetchStatisticsData() {
    this.loading = true;
    this.noOfCountriessToDisplay = 10;

    this.statisticsServiceProxy
      .GetUserVisitsByMonth(this.selectedMonth, this.selectedYear, this.usersCategory)
      .subscribe(
        (result) => {
          let users = result;
          this.visitsForChart = this.getUserDeviceInfo(users);

          let countriesGroup: Record<string, UserWithDeviceInfo[]> =
            this.visitsForChart.reduce((acc, user) => {
              acc[user.country] = (acc[user.country] || []).concat(user);
              return acc;
            }, {} as Record<string, UserWithDeviceInfo[]>);

          this.usersGroupedByCountry = Object.entries(countriesGroup)
            .map(([country, users]) => ({ country, users }))
            .sort((a, b) => b.users.length - a.users.length);

          let devices: Record<string, UserWithDeviceInfo[]> = this.visitsForChart.reduce(
            (acc, user) => {
              acc[user.device] = (acc[user.device] || []).concat(user);
              return acc;
            },
            {} as Record<string, UserWithDeviceInfo[]>
          );

          this.usersGroupedByDevice = Object.entries(devices)
            .map(([device, users]) => ({ device, users }))
            .sort((a, b) => b.users.length - a.users.length);

          let OSs: Record<string, UserWithDeviceInfo[]> = this.visitsForChart.reduce(
            (acc, user) => {
              acc[user.os] = (acc[user.os] || []).concat(user);
              return acc;
            },
            {} as Record<string, UserWithDeviceInfo[]>
          );

          this.usersGroupedByOS = Object.entries(OSs)
            .map(([os, users]) => ({ os, users }))
            .sort((a, b) => b.users.length - a.users.length);

          let browsers: Record<string, UserWithDeviceInfo[]> =
            this.visitsForChart.reduce((acc, user) => {
              acc[user.browser] = (acc[user.browser] || []).concat(user);
              return acc;
            }, {} as Record<string, UserWithDeviceInfo[]>);

          this.usersGroupedByBrowser = Object.entries(browsers)
            .map(([browser, users]) => ({ browser, users }))
            .sort((a, b) => b.users.length - a.users.length);

          this.loading = false;

          this.updateCharts();
        },
        (error) => {
          this.processServiceError(error);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  get noOfUsersForTheMonth() {
    return this.visitsForChart.length;
  }

  transformCountryName(name: string): string {
    return name == 'null' ? 'Unknown' : name;
  }

  getUserDeviceInfo(users: UserVisit[]): UserWithDeviceInfo[] {
    let result: UserWithDeviceInfo[] = [];
    users?.forEach((user) => {
      let { browser, device, os } = UAParser(user.userAgent);
      result.push({
        ...user,
        ...{ browser: browser.name, os: os.name, device: device.type },
        address: user.userAddress
      });
    });

    return result;
  }

  updateCharts() {
    // if (this.deviceChart) {
    //   this.deviceChart.data.labels = this.usersGroupedByDevice.map(
    //     (u) => u.device
    //   );
    //   this.deviceChart.data.datasets[0].data = this.usersGroupedByDevice.map(
    //     (u) => u.users.length
    //   );
    //   this.deviceChart.update();
    // } else {
    //   this.deviceChart = new Chart('device-chart', {
    //     type: 'doughnut',
    //     data: {
    //       labels: this.usersGroupedByDevice.map((u) => u.device),
    //       datasets: [
    //         {
    //           data: this.usersGroupedByDevice.map((u) => u.users.length),
    //           backgroundColor: this.colors,
    //           borderRadius: 2,
    //         },
    //       ],
    //     },
    //     options: {
    //       aspectRatio: 2.5,
    //       plugins: {
    //         legend: {
    //           display: false,
    //         },
    //         tooltip: {
    //           enabled: false,
    //         },
    //       },
    //       scales: {
    //         x: {
    //           display: false,
    //         },
    //         y: {
    //           display: false,
    //         },
    //       },
    //     },
    //   });
    // }

    console.log('os names', this.usersGroupedByOS.map((u) => u.os))
    console.log('os numbers', this.usersGroupedByOS.map((u) => u.users.length))
    if (this.osChart) {
      this.osChart.data.labels = this.usersGroupedByOS.map((u) => u.os);
      this.osChart.data.datasets[0].data = this.usersGroupedByOS.map((u) => u.users.length);
      this.osChart.update();
    } else {
      this.osChart = new Chart('os-chart', {
        type: 'doughnut',
        data: {
          labels: this.usersGroupedByOS.map((u) => u.os),
          datasets: [
            {
              data: this.usersGroupedByOS.map((u) => u.users.length),
              backgroundColor: this.colors,
              borderRadius: 2,
            },
          ],
        },
        options: {
          aspectRatio: 2.5,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      });
    }

    if (this.browserChart) {
      this.browserChart.data.labels = this.usersGroupedByBrowser.map(
        (u) => u.browser
      );
      this.browserChart.data.datasets[0].data = this.usersGroupedByBrowser.map(
        (u) => u.users.length
      );
      this.browserChart.update();
    } else {
      this.browserChart = new Chart('browser-chart', {
        type: 'doughnut',
        data: {
          labels: this.usersGroupedByBrowser.map((u) => u.browser),
          datasets: [
            {
              data: this.usersGroupedByBrowser.map((u) => u.users.length),
              backgroundColor: this.colors,
              borderRadius: 2,
            },
          ],
        },
        options: {
          aspectRatio: 2.5,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      });
    }
  }

  formatNumber(number: number, decimalPlaces: number) {
    // Check if number has decimals (avoiding floating-point precision issues)
    if (Math.abs(number - Math.floor(number)) > Number.EPSILON) {
      return number.toFixed(decimalPlaces);
    } else {
      return number.toString();
    }
  }

  fetchUserRegsitrations() {
    this.isLoading = true;

    let datefrom = this.filterForm.get('dateFrom').value;
    let dateTo = this.filterForm.get('dateTo').value;

    if(this.usersCategory == 'all'){
      datefrom = new Date(this.selectedYear, this.selectedMonth -1, 1); // first day of selected month
      dateTo = new Date(this.selectedYear, this.selectedMonth, 0); // last day of selectedmonth
    }

    this.statisticsServiceProxy
      .GetUserVisits(
        this.searchValue,
        this.filterForm.get('country').value,
        this.filterForm.get('tier').value,
        datefrom,
        dateTo,
        this.usersCategory,
        this.pageSize,
        this.page
      )
      .subscribe(
        (result) => {
          this.userVisits = this.getUserDeviceInfo(result.items);
          this.totalCount = result.totalItems;
          this.isLoading = false;
        },
        (error) => {
          this.loading = false;
          this.showErrorModal(
            error.message || JSON.parse(error.response).message
          );
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  public async saveData(): Promise<void> {
    const allData = await this.statisticsServiceProxy
      .GetUserVisits(
        this.searchValue,
        this.filterForm.get('country').value,
        this.filterForm.get('tier').value,
        this.filterForm.get('dateFrom').value,
        this.filterForm.get('dateTo').value,
        this.usersCategory,
        this.pageSize,
        this.page
      )
      .toPromise();

    const csvString = this.convertObjectToCSV(allData.items);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'user_registrations by country.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n');
    return csv;
  }

  pageChanged($event: any): void {
    this.page = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.fetchUserRegsitrations();
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.router.navigate(['/login']);
    } else
      this.showErrorModal(error.message || JSON.parse(error.response).message);
  }
}
