<div class="card-content" (click)="navigateToDeal()">
  <div class="d-flex align-items-center justify-content-between">
    <div class="token-name token-name-inline">
      <div class="token-name__img">
        <img [src]="item.logoURL?item.logoURL:'../../assets/images/no-image.svg'" alt=""/>
      </div>
      <div class="token-name__info">
        <div class="token-title">{{item.name}}</div>
        <div class="token-symbol">{{item.rewardTokenSymbol}}</div>
      </div>
    </div>

    <button mat-icon-button
            (click)="navigateToEditDeal()"
            matTooltip="Edit Deal -{{item.dealAddress}}"
            matTooltipPosition="above">
      <span class="icomoon-edit"></span>
    </button>
  </div>

  <div class="deal-card__info">
    <div class="row g-0">
      <div class="col-lg-6">
        <div class="caption text-muted">Total Raise</div>
        <div class="h6 deal-name">
          {{item.maxDistributedTokenAmount | shiftDecimals: -18| number: '1.0-2'}} {{item.rewardTokenSymbol}}
        </div>
        <div class="small-text">
          {{getTotalRaiseETH(item)| number: '1.0-2'}} {{item.paymentTokenSymbol}}
        </div>
      </div>
      <div class="col-lg-6">
        <div class="progress deal-progress progress-lg">
          <div class="progress-bar"
               role="progressbar"
               [style.width.%]="getDistributedPercent"></div>

          <div class="progress-label" *ngIf="isUpcoming || isStarted">
            <span *ngIf="isUpcoming">Starts in</span>
            <span *ngIf="isStarted">Time left</span>

            <span class="countdown ms-1">
              <span *ngIf="timerViewDays">{{timerViewDays}} d</span>
              <span *ngIf="timerViewHours"> {{timerViewHours}} h</span>
              <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}} m</span>
              <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}} s</span>
            </span>
            <span class="rainbow-border"></span>
          </div>

          <div class="progress-label" *ngIf="isFinished">Finished <span class="rainbow-border"></span></div>
        </div>
        <div class="progress-status">
          <div class="progress-status__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
          <div class="progress-status__value text-muted">
            {{tokensForDistribution| shiftDecimals:-item.rewardDecimal| number: '1.0-2'}}/{{item.maxDistributedTokenAmount | shiftDecimals: -18| number: '1.0-2'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-footer">
  <div class="row">
    <div class="col-6 col-md-4">
      <div class="caption text-muted">
        Ratio 1 {{item.paymentTokenSymbol}}
      </div>
      <div class="subtitle-1 mb-1">
        {{getRatioPer1ETH(item) | number: '1.0-2' }}
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="caption text-muted mb-1">Type</div>
      <div class="subtitle-1">{{item.dealType | dealTypeName}}</div>
    </div>
    <div class="col-md-4 mt-4 mt-md-0">
      <div class="caption text-muted mb-1">Target Network</div>
      <div class="network-name">
        <img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img" alt=""/>
        <span class="network-name__title">{{item.targetNetwork | networkName }}</span>
      </div>
    </div>
  </div>
</div>
