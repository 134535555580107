<main class="statistics-page">

  <div class="date-filter card p-4 m-2 row">
    <div class="select col-6">
      <mat-form-field color="primary">
        <mat-label>Month</mat-label>
        <mat-select [disabled]="loading" [(ngModel)]="selectedMonth" (selectionChange)="filterChanged()">
          <mat-option *ngFor="let month of months" [value]="months.indexOf(month) + 1">
            {{month}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="select col-6">
      <mat-form-field>
        <mat-label>Year</mat-label>
        <mat-select [disabled]="loading" [(ngModel)]="selectedYear" (selectionChange)="filterChanged()">
          <mat-option *ngFor="let year of years" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row m-2">
    <button mat-stroked-button class="col-12 mt-2" [class.selected-button]="usersCategory == 'all'" (click)="changeUsersCategory('all')">Show All Users</button>
    <button mat-stroked-button class="col-12 mt-2" [class.selected-button]="usersCategory == 'active'" (click)="changeUsersCategory('active')">Show Active Users</button>
    <button mat-stroked-button class="col-12 mt-2" [class.selected-button]="usersCategory == 'registered'" (click)="changeUsersCategory('registered')">Show Registered Users</button>
  </div>

    <section class="statistics-header m-2">
        <div class="statistics-left">
          <div class="card p-3">
            <mat-spinner *ngIf="loading" class="spinner" diameter="40"></mat-spinner>
              <div [ngStyle]="{'display': loading ? 'none' : 'flex'}" class="row">
                <div class="col-12">
                  <table class="table table-responsive">
                    <thead class="tex">
                      <tr>
                        <th colspan="4"><mat-icon class="mr-3">people</mat-icon> User Statistics by country</th>
                      </tr>
                      <tr>
                        <th>#</th>
                        <th>Country</th>
                        <th>Quantity</th>
                        <th>%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let group of usersGroupedByCountry; index as i" class="country-row">
                        <td>{{i + 1}}</td>
                        <td><img [src]="group.users[0].countryFlagUrl" style="margin-right: 8px;"> {{transformCountryName(group.country)}}
                        </td>
                        <td>{{group.users.length}}</td>
                        <td>{{(100 * group.users.length / visitsForChart.length).toFixed(1)}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button *ngIf="noOfCountriessToDisplay < noOfCountriesInMonth()" mat-stroked-button color="primary" color="primary" class="w-100 mt-3" (click)="loadMoreCountries()">Load More</button>
                </div>
              </div>
          </div>
        </div>
        <div class="statistics-right">
          <!-- <div class="card p-4 mb-40">
            <div class="card-header">Device Statistics</div>
            <div class="card-content">
              <div class="statistics-data-container">
                <div class="statistics-data-left">
                  <canvas id="device-chart">{{ deviceChart }}</canvas>
                </div>
                <div class="statistics-data-right">
                  <table class="table table-responsive">
                    <tbody>
                      <tr *ngFor="let group of usersGroupedByDevice; let i = index">
                        <td><span class="dot" [style]="{'background-color': colors[i]}"></span>{{group.device === 'undefined' ? "Unknown" : group.device }} </td>
                        <td>{{formatNumber(group.users.length * 100 / visitsForChart.length, 1)}}% </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card p-4 mb-40">
            <div class="card-header d-flex align-items-center">
              <i class="icon icomoon-chart-pie me-12 text-muted"></i>
              OS Statistics
            </div>
            <div class="card-content">
              <div class="statistics-data-container">
                <div class="statistics-data-left">
                  <canvas id="os-chart">{{ osChart }}</canvas>
                </div>
                <div class="statistics-data-right">
                  <table class="table table-responsive">
                    <tbody>
                      <tr *ngFor="let group of usersGroupedByOS; let i = index">
                        <td><span class="dot" [style]="{'background-color': colors[i]}"></span>{{group.os === 'undefined' ? "Unknown" : group.os }} </td>
                        <td>{{formatNumber(group.users.length * 100 / visitsForChart.length, 1)}}% </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-4 mb-40">
            <div class="card-header d-flex align-items-center">
              <i class="icon icomoon-chart-pie me-12 text-muted"></i>
              Browser Statistics
            </div>
            <div class="card-content">
              <div class="statistics-data-container">
                <div class="statistics-data-left">
                  <canvas id="browser-chart">{{ browserChart }}</canvas>
                </div>
                <div class="statistics-data-right">
                  <table class="table table-responsive">
                    <tbody>
                      <tr *ngFor="let group of usersGroupedByBrowser; let i = index">
                        <td><span class="dot" [style]="{'background-color': colors[i]}"></span>{{group.browser === 'undefined' ? "Unknown" : group.browser }} </td>
                        <td>{{formatNumber(group.users.length * 100 / visitsForChart.length, 1)}}% </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section>
      <div class="card mb-4" *ngIf="usersCategory != 'all'">
        <div class="row p-4">
          <div class="col">
            <mat-form-field class="white-label">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label >Search</mat-label>
              <input placeholder="Search by email or wallet address"  matInput [(ngModel)]="searchValue" value="">
            </mat-form-field>
          </div>
          <div class="col-3">
            <button type="button" mat-raised-button color="primary" class="bg-success" [disabled]="isLoading" (click)="fetchUserRegsitrations()">Search <span class="rainbow-border"></span></button>
            <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base m-3"><img (click)="saveData()" src="../../assets/svg/download-solid.svg"/></button>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="usersCategory != 'all'">
        <form [formGroup]="filterForm" class="p-4">
            <p >Filter by:</p>
            <div class="row p-3">
              <div class="col-3">
                <mat-form-field class="example-full-width">
                    <mat-label>Country</mat-label>
                    <input formControlName="country" matInput [matAutocomplete]="countryList">
                    <mat-autocomplete #countryList="matAutocomplete">
                      <mat-option *ngFor="let country of filteredCountryOptions | async" [value]="country">
                        {{ country }}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
                <div class="col-4">
                    <mat-form-field>
                        <mat-label>Tier</mat-label>
                        <mat-select formControlName="tier">
                            <mat-option selected [value]="-2">Show All</mat-option>
                            <mat-option *ngFor="let tier of tiers" [value]="tier.index">
                                {{tier.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-5">
                    <mat-form-field>
                        <mat-label>Date</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate formControlName="dateFrom" placeholder="Start date">
                            <input matEndDate formControlName="dateTo" placeholder="End date">
                        </mat-date-range-input>

                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>

                </div>
                <div class="col-12">
                    <button (click)="fetchUserRegsitrations()" type="button" mat-raised-button color="primary" class="bg-success w-100" [disabled]="isLoading">
                        <mat-icon>search</mat-icon>
                        Apply Filter
                      <span class="rainbow-border"></span>
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="card m-2">
        <table class="table table-responsive">
            <thead>
                <tr>
                  <th *ngIf="usersCategory == 'registered'">Registration</th>
                  <th *ngIf="usersCategory != 'registered'">Date</th>
                    <th *ngIf="usersCategory != 'all'">Email</th>
                    <th *ngIf="usersCategory != 'all'">Wallet</th>
                    <th>Country</th>
                    <th>Browser</th>
                    <th>OS</th>
                    <th *ngIf="usersCategory != 'all'">Tier</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngIf="!userVisits?.length">
                <td class="m-3 text-center" [attr.colspan]="usersCategory == 'all' ? 3 : 6" >No item to display</td>
              </tr>
              <tr *ngFor="let visit of userVisits">
                <td *ngIf="usersCategory == 'registered'">{{ visit.date }}</td>
                <td *ngIf="usersCategory != 'registered'">{{visit.date}}</td>
                <td *ngIf="usersCategory != 'all'">{{visit.email}}</td>
                <td *ngIf="usersCategory != 'all'">{{visit.address}}</td>
                <td><img [src]="visit.countryFlagUrl" style="margin-right: 8px;"> {{transformCountryName(visit.country)}}</td>
                <td>{{visit.browser}} </td>
                <td>{{visit.os}} </td>
                <td *ngIf="usersCategory != 'all'"><img [src]="visit.tierIndex| imgTierPipe" class="tier-image" />{{visit.tierIndex
                  | nameTierPipe}} </td>
              </tr>
            </tbody>
        </table>
    </div>

    <div class="card mt-3" *ngIf="usersCategory != 'all'">
        <mat-paginator [length]="totalCount"
                           [pageIndex]="page"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="[5, 10, 25, 100]"
                           (page)="pageChanged($event)"
                           aria-label="Select page">
            </mat-paginator>
    </div>
    </section>
  </main>
