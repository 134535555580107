<main class="dashboard-main">
  <section class="__users-table">
    <div class="card p-4 mb-40">
      <div class="form-group-inline wrap-in-mob users-filter-inputs">
        <mat-form-field appearance="fill" class="icon-form-field">
          <i class="icon icomoon-search text-muted"></i>
          <mat-label>Search by email, wallet, address book</mat-label>
          <textarea matInput
                    [(ngModel)]="searchString"
                    name="searchString"
                    cdkTextareaAutosize
                    cdkAutosizeMaxRows="10">
          </textarea>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col">
          <mat-label>Tier</mat-label>
          <mat-select [(ngModel)]="tierIndex">
            <mat-option [value]="-1">Any</mat-option>
            <mat-option *ngFor="let tier of tiers" [value]="tier">{{tier| nameTierPipe}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button type="button" mat-raised-button color="primary" (click)="searchClick()" class="search-btn">
          Search
          <span class="rainbow-border"></span>
        </button>
        <div><button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base m-0"><img (click)="saveData()" src="../../assets/svg/download-solid.svg"/></button></div>
      </div>
    </div>
    <mat-spinner *ngIf="loading" class="__loading" diameter="40"></mat-spinner>
    <table [style]="loading ? 'display:none;' : ''"
           class="table table-responsive"
           matSort (matSortChange)="sortData($event)"
           [matSortActive]="sortByColumn"
           [matSortDirection]="sortDirection">
      <thead>
        <tr>
          <th class="__date-col" mat-sort-header="date">Registration</th>
          <th class="__email-col" mat-sort-header="email">
            <span matTooltip="Sort by e-mail" matTooltipPosition="above">
              E-mail
            </span>
          </th>
          <th class="__tier-col">Tier</th>
          <th class="__kys-col">KYC</th>
          <th class="__wallet-col">Wallet</th>
        </tr>
      </thead>
      <tbody>
        <!--creationDateTime!: moment.Moment;
        email!: string | undefined;
        ethAddress!: string | undefined;
        sumAndSupApplicantId!: string | undefined;
        needToLoadDocuments!: boolean;
        kycConfirmed!: boolean;-->
        <tr *ngFor="let item of users; index as i">
          <td class="__date-col td-responsive td-responsive-full" data-title="Registration">
            {{item.creationDateTime}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="E-mail">
            {{item.email}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="Tier">
            {{item.tierIndex | nameTierPipe}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="KYC">
            <span *ngIf="item.kycConfirmed" class="__kys-status --passed">
              <i class="icomoon-checked"></i>
              Passed
            </span>
            <span *ngIf="!item.kycConfirmed" class="__kys-status">
              <i class="icomoon-close-fill"></i>
              No
            </span>
          </td>
          <td class="td-responsive td-responsive-full" data-title="Wallet">
            <div class="d-flex align-items-center justify-content-between">
              <button class="__address address-btn --copy">
                <span [cdkCopyToClipboard]="item.ethAddress">{{item.ethAddress}}</span>
              </button>

              <a mat-icon-button class="ms-2" matTooltip="Address Book" matTooltipPosition="above"
                (click)="navigateToUserDetails(item.ethAddress)">
                <span class="icomoon-address-book"></span>
              </a>

              <a [href]="getApplicantURL(item.sumAndSupApplicantId)" mat-icon-button matTooltip="KYC link"
                matTooltipPosition="above" target="_blank" *ngIf="item.sumAndSupApplicantId" class="ms-2">
                <span class="icomoon-kys"></span>
              </a>

              <button *ngIf="item.sumAndSupApplicantId" type="button" (click)="syncKYCClick(item)" mat-icon-button matTooltip="Sync KYC"
                 matTooltipPosition="above" class="ms-2">
                <span class="icomoon-eye"></span>
              </button>

            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <mat-paginator
      hidePageSize
      [length]="totalCount"
      [pageIndex]="page"
      [pageSize]="10"
      (page)="pageChanged($event)"
      aria-label="Select page" class="user-paginator">
    </mat-paginator>
  </section>

  <section class="__users-table">
    <div  class="card p-4">
      <div class="subtitle-1 mb-2">Snapshots</div>
      <div class="subtitle-2 mb-4">
        Current snapshot:
        <span *ngIf="snapshotStatus && !snapshotLoading">
          <span>{{snapshotStatus?.status}} </span>
          <span *ngIf="snapshotStatus?.endDate"> at {{snapshotDate}}</span>
          <span> ({{snapshotStatus?.progressPercent}}%)</span>
          <button type="button" class="snapshot-download" *ngIf="snapshotStatus?.status == 'completed'" [disabled]="snapshotLoading" mat-icon-button (click)="snapshotClick()">
            <i class="icon icomoon-down"></i>
          </button>
        </span>
        <span *ngIf="snapshotLoading">initializing...</span>
        <span *ngIf="!snapshotLoading && !snapshotStatus">none</span>
      </div>
      <button type="button" [disabled]="snapshotStatus?.status == 'running' || snapshotLoading" mat-raised-button color="primary" (click)="showSnapshotDlg()">
        New snapshot
      </button>
    </div>
  </section>

  <section class="__users-table">
    <div  class="card p-4 mb-40">
      <div class="subtitle-1 mb-4">Bonus Amounts</div>
        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>Enter Bonus amounts in CSV format (address,amount) e.g. 0xaceaecb820be3f147ff40d1c221b25f10ee8dd92,1500</mat-label>
          <textarea matInput [(ngModel)]="bonusAmounts"
                    name="bonusAmounts"
                    rows="15">
          ></textarea>
        </mat-form-field>
        <button type="button"
                (click)="setBonusAmounts()"
                class="mt-4"
                [disabled]="waiting"
                mat-raised-button
                color="primary">
          Set Bonus Amounts
        </button>
    </div>
  </section>

</main>
