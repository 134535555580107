import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy, DealUpdateDTO } from '../../service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.scss'],
})
export class TiersPageComponent extends ComponentBase implements OnInit {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private route: ActivatedRoute
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';

  allTiers: any[] = new Array();
  newTierBlpAmount: number;
  newTierTimeLockups: number;

  updateTierIndex: number = -1;
  updateTierBlpAmount: number;
  updateTierTimeLockups: number;

  async ngOnInit() {
    await this.web3Service.initWeb3();

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});
    this.account = this.userSessionProvider.linkedWallet;
    this.updateTiersContractData();
  }


  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = "";
    //this.balance = null;
    //console.log('eventLogout')
    //if (this.timerId) {
    //  console.log('clearInterval');
    //  clearInterval(this.timerId);
    //}
  }


  async updateTiersContractData() {
    console.log('Get contract data')

    this.allTiers = new Array();
    let tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiesrLenght; i++) {
      let tier = await this.web3Service.getDealLockupsTiers(i);
      console.log(tier);
      this.allTiers.push(tier);
    }
  }

  select(index: number) {
    this.updateTierIndex = index;
    this.updateTierBlpAmount = this.toNumberFromWei(this.allTiers[index].blpAmount, 18);
    this.updateTierTimeLockups = this.allTiers[index].timeLockups;
  }

  async updateTierClick(): Promise<void> {

    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealLockupsUpdateTier(this.account, this.updateTierIndex,
                                                this.updateTierBlpAmount, this.updateTierTimeLockups);

    //contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      //dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateTierIndex = -1;
      this.updateTierBlpAmount = null;
      this.updateTierTimeLockups = null;
      this.updateTiersContractData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async addTierClick(): Promise<void> {

    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealLockupsAddTier(this.account, this.newTierBlpAmount, this.newTierTimeLockups);

    //contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      //dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateTiersContractData();
      this.newTierBlpAmount = null;
      this.newTierTimeLockups = null;
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

}
