<main class="analytics-page">
    <section class="analytics-header">
        <div class="analytics-left">
            <div class="date-filter card p-4">
                <div class="select">
                    <mat-form-field color="primary">
                        <mat-label >Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonth" (selectionChange)="filterChanged()">
                          <mat-option *ngFor="let month of months" [value]="months.indexOf(month) + 1">
                            {{month}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="select">
                    <mat-form-field>
                        <mat-label >Year</mat-label>
                        <mat-select [(ngModel)]="selectedYear" (selectionChange)="filterChanged()">
                          <mat-option *ngFor="let year of years" [value]="year">
                            {{year}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
            <div class="card statistics mt-3">
              <table class="table table-responsive">
                <thead>
                  <tr>
                    <th class="__date-col" ><mat-icon>bar_chart</mat-icon> Statistics</th>
                    <th class="__tier-col">
                      <mat-icon class="text-success">error_outline</mat-icon>
                    </th>
                  </tr>
                </thead>
                <mat-spinner *ngIf="loading" class="spinner" diameter="40"></mat-spinner>
                <tbody *ngIf="!loading">
                  <tr>
                    <td> <a (click)="changeComponent('new_user_registrations')">New Registrations</a></td>
                    <td>{{dashboard?.newRegistrations}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('new_user_registrations_with_KYC')">With KYC</a></td>
                    <td>{{dashboard?.registrationsWithKYC}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('referred_users')">Referred Users</a></td>
                    <td>{{dashboard?.referredUsers}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('tier_upgrade')">Tier Upgrades</a></td>
                    <td>{{dashboard?.tierUpgrades}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('tier_downgrade')">Tier Downgrades</a></td>
                    <td>{{dashboard?.tierDowngrades}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('new_tier_members')">New Tier Members</a></td>
                    <td>{{dashboard?.newTierMembers}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('tiers_lost')">Tiers Lost</a></td>
                    <td>{{dashboard.usersLost}}</td>
                  </tr>
                  <tr>
                    <td><a (click)="changeComponent('new_deals')">New Deals</a></td>
                    <td>{{dashboard.newDeals}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="analytics-right">
          <div class="card p-4 mb-40">
            <mat-spinner *ngIf="loading" class="spinner" diameter="40"></mat-spinner>
            <div [ngStyle]="{'display': loading ? 'none' : 'flex'}" class="row">
              <!-- <div class="col-4 d-flex flex-column">
                <div class="chart-container">
                  <canvas id="deals-chart">{{ dealsChart }}</canvas>
                </div>
              </div> -->
              <div class="col-12">
                <table class="table table-responsive">
                  <tbody>
                    <tr *ngFor="let deal of dashboard?.deals.slice(0, noOfDealsToDisplay)">
                      <td>{{deal.dealName}}</td>
                      <td>{{deal.participantsCount}}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <button mat-stroked-button color="primary" color="primary" class="w-100 mt-3" (click)="loadMoreDeals()">Load More</button>
                      </td>
                    </tr>
                    <tr>
                      <td>Average Deals Participants</td>
                      <td>{{getAverageDealsParticipants()}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card p-4 mb-40">
            <div class="card-header d-flex align-items-center">
              <i class="icon icomoon-chart-pie me-12 text-muted"></i>
              User Tiers
            </div>
            <div class="card-content">
              <div *ngIf="!loading" class="mt-5 row">
                <mat-slide-toggle [(ngModel)]="countNoTierUsers" labelPosition="before">Show All</mat-slide-toggle>
                <div class="col-4 tier-progresses">
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(-1)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-success" role="progressbar" [style.width.%]="getTierUserCountPercentage(-1)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(0)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-copper" role="progressbar" [style.width.%]="getTierUserCountPercentage(0)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(1)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-bronze" role="progressbar" [style.width.%]="getTierUserCountPercentage(1)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(2)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-silver" role="progressbar" [style.width.%]="getTierUserCountPercentage(2)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(3)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-gold" role="progressbar" [style.width.%]="getTierUserCountPercentage(3)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(4)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-titanium" role="progressbar" [style.width.%]="getTierUserCountPercentage(4)"></div>
                    </div>
                  </div>
                  <div class="tier-progress">
                    <div class="">{{getTierUserCountPercentage(5)}}%</div>
                    <div class="progress progress-sm progress-success">
                      <div class="progress-bar bg-platinum" role="progressbar" [style.width.%]="getTierUserCountPercentage(5)"></div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <table class="table table-responsive">
                    <tbody>
                      <tr>
                        <td><span class="dot bg-success"></span> No Tier</td>
                        <td>{{ countNoTierUsers ? getTierUserCount(-1) : 0 }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-copper"></span> Copper Bull</td>
                        <td>{{ getTierUserCount(0) }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-bronze"></span> Bronze Bull</td>
                        <td>{{ getTierUserCount(1) }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-silver"></span> Silver Bull</td>
                        <td>{{ getTierUserCount(2) }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-gold"></span> Golden Bull</td>
                        <td>{{ getTierUserCount(3) }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-titanuim"></span> Titanium Bull</td>
                        <td>{{ getTierUserCount(4) }}</td>
                      </tr>
                      <tr>
                        <td><span class="dot bg-platinum"></span> Platinum Bull</td>
                        <td>{{ getTierUserCount(5) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{countNoTierUsers ? dashboard.totalUsersCount : dashboard.totalUsersCount - getTierUserCount(-1)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="test-white">
                <mat-panel-title> Virtual User Tiers </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="card p-4">
                <div class="card-content">
                  <div *ngIf="!loading" class="mt-5 row">
                    <mat-slide-toggle [(ngModel)]="countNoVirtualTierUsers" labelPosition="before">Show All</mat-slide-toggle>
                    <div class="col-4 tier-progresses">
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(-1)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-success" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(-1)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(0)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-copper" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(0)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(1)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-bronze" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(1)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(2)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-silver" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(2)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(3)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-gold" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(3)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(4)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-titanium" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(4)"></div>
                        </div>
                      </div>
                      <div class="tier-progress">
                        <div class="">{{getVirtualTierUserCountPercentage(5)}}%</div>
                        <div class="progress progress-sm progress-success">
                          <div class="progress-bar bg-platinum" role="progressbar" [style.width.%]="getVirtualTierUserCountPercentage(5)"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <table class="table table-responsive">
                        <tbody>
                          <tr>
                            <td><span class="dot bg-success"></span> No Tier</td>
                            <td>{{ countNoVirtualTierUsers ? getVirtualTierUserCount(-1) : 0 }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-copper"></span> Copper Bull</td>
                            <td>{{ getVirtualTierUserCount(0) }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-bronze"></span> Bronze Bull</td>
                            <td>{{ getVirtualTierUserCount(1) }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-silver"></span> Silver Bull</td>
                            <td>{{ getVirtualTierUserCount(2) }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-gold"></span> Golden Bull</td>
                            <td>{{ getVirtualTierUserCount(3) }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-titanuim"></span> Titanium Bull</td>
                            <td>{{ getVirtualTierUserCount(4) }}</td>
                          </tr>
                          <tr>
                            <td><span class="dot bg-platinum"></span> Platinum Bull</td>
                            <td>{{ getVirtualTierUserCount(5) }}</td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>{{countNoVirtualTierUsers ? dashboard.totalUsersCount : dashboard.totalUsersCount - getTierUserCount(-1)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
    </section>

    <section class="container-fluid">
      <app-analytics-new-user-registrations
        *ngIf="selectedComponent == 'new_user_registrations' || selectedComponent == 'new_user_registrations_with_KYC' || selectedComponent == 'referred_users'"
        [selectedSubComponent]="selectedComponent"
        ></app-analytics-new-user-registrations>

      <app-analytics-tier-changes
        *ngIf="selectedComponent == 'tier_upgrade' || selectedComponent == 'tier_downgrade' || selectedComponent == 'new_tier_members' || selectedComponent == 'tiers_lost' "
        [selectedSubComponent]="selectedComponent"
        ></app-analytics-tier-changes>

      <app-analytics-deal-details
        *ngIf="selectedComponent == 'new_deals'"
        [deals]="dashboard?.deals"></app-analytics-deal-details>

    </section>
  </main>
