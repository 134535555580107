import { environment } from 'src/environments/environment';

export interface MenuItem {
  readonly label: string;
  readonly routePath: string[] | string;
  readonly supportedChains: number[];
  readonly icon?: string;
  readonly childItems?: MenuItem[];
  readonly isExternal?: boolean;
  readonly disabled?: boolean;
  readonly forSuperAdmin?: boolean;
}

export const menu: MenuItem[] = [
  {
    icon: 'icomoon-users',
    label: 'Users',
    routePath: '/users',
    supportedChains: [84532, 8453],
  },
  {
    icon: 'icomoon-users',
    label: 'Admins',
    routePath: '/admins',
    supportedChains: [84532, 8453],
  },
  // {
  //   label: 'Wallet Changes',
  //   icon: 'icomoon-address-book',
  //   routePath: ['/change-wallets'],
  //   supportedChains: [84532, 8453],
  //   forSuperAdmin: true,
  // },
  {
    icon: 'icomoon-chart-down',
    label: 'Penalties',
    routePath: '/penalties',
    disabled: true,
    supportedChains: [84532, 8453],
    forSuperAdmin: true,
  },
  {
    label: 'Tiers',
    icon: 'icomoon-tiers',
    routePath: ['/tiers'],
    supportedChains: [84532, 8453],
    forSuperAdmin: true,
  },
  {
    label: 'All Deals',
    icon: 'icomoon-claim',
    routePath: ['/deals', `/create-wallet-deal`, '/deal-detail', '/equity-deals'],
    supportedChains: [84532, 8453],
    forSuperAdmin: true,
    childItems: [
      {
        label: 'Deals',
        routePath: `/deals`,
        supportedChains: [84532, 8453],
      },
      // {
      //   label: "Equity Deals",
      //   routePath: `/equity-deals`,
      //   supportedChains: [84532, 8453],
      // },
      {
        label: 'Create Deal',
        routePath: `/create-wallet-deal`,
        supportedChains: [84532, 8453],
      },
      // {
      //   label: 'Create Equity Deal',
      //   routePath: `/equity-deal`,
      //   supportedChains: [84532, 8453],
      // },
    ],
  },
  {
    label: 'Pools',
    icon: 'icomoon-pools',
    routePath: [
      '/staking',
      `/farming`,
      `/create-pool`,
      `/pool-detail`,
      '/edit-pool',
    ],
    supportedChains: [84532, 8453],
    forSuperAdmin: true,
    childItems: [
      {
        label: 'Staking Pools',
        routePath: `/staking`,
        supportedChains: [84532, 8453],
      },
      // {
      //   label: 'Farming Pools',
      //   routePath: `/farming`,
      //   supportedChains: [84532, 8453],
      // },
      {
        label: 'Create Pool',
        routePath: `/create-pool`,
        supportedChains: [84532, 8453],
      },
    ],
  },
  {
    icon: 'icomoon-info',
    label: 'Voting',
    routePath: '/voting',
    supportedChains: [84532, 8453],
    forSuperAdmin: true
  },
  {
    icon: 'icomoon-info',
    label: 'Referrals',
    routePath: '/referrals',
    supportedChains: [84532, 8453],
    forSuperAdmin: false
  },
  {
    label: 'Settings',
    icon: 'icomoon-warning',
    routePath: '/settings',
    supportedChains: [84532, 8453],
    disabled: true,
    forSuperAdmin: true,
  },
  {
    label: "Analytics",
    icon: "icomoon-dashboard",
    routePath: "/analytics",
    supportedChains: [84532, 8453],
  },
  {
    label: "Statistics",
    icon: "icomoon-dashboard",
    routePath: "/statistics",
    supportedChains: [84532, 8453]
  },
];
