<div class="user-polls-page">
  <div class="create-poll-wrapper">
    <a href="/create-poll" mat-raised-button color="primary" class="btn-icon btn-icon-left">
      <i class="icon icomoon-plus"></i>
      Create Poll
      <span class="rainbow-border"></span>
    </a>
  </div>

  <div class="user-polls-wrapper">
    <div class="user-poll" *ngFor="let poll of polls">
      <div class="user-poll-title">
        <img src="assets/images/polls/info-icon.svg" alt="info-icon">
        <p class="user-poll-title-text">{{poll.title}}</p>
      </div>

      <div class="user-poll-description" [innerHTML]="poll.description | safeHtml"></div>

      <p class="user-poll-date">{{ showLocalDate(poll.createdDate) }}</p>

      <div class="user-polls-votes">
        <div class="user-polls-vote" *ngFor="let vote of poll.options">
          <div class="user-polls-vote-info">
            <div class="d-flex align-items-center">
              <i class="icon icomoon-plus cursor-pointer" (click)="addExtraVote(poll.id, vote.id)"></i>
              <span class="user-polls-vote-info-answer">{{vote.option}}</span>
            </div>
            <span class="user-polls-vote-info-percentage">{{vote.percentage}}%</span>
          </div>
          <div class="user-polls-vote-progress-wrapper">
            <i class="icon icomoon-checked" *ngIf="optionUserVotedFor(vote.id)"></i>
            <div class="user-polls-vote-progress">
              <div class="user-polls-vote-progress-bar" role="progressbar" [style.width.%]="vote.percentage"></div>
            </div>
          </div>
        </div>

        <p class="user-polls-votes-total">{{poll.votes.length}} votes</p>
      </div>

      <div class="user-poll-actions">
        <div class="user-poll-actions-btns">
          <a mat-raised-button color="primary" class="btn-icon btn-icon-left" (click)="editUserPoll(poll.id)">
            <i class="icon icomoon-edit"></i>
            Edit
          </a>

          <a mat-raised-button color="primary" class="btn-icon btn-icon-left" (click)="finishPoll(poll)">
            <i *ngIf="!poll.isFinished" class="icon icomoon-close"></i>
            {{poll.isFinished ? 'Resume' : 'Stop'}}
            <span class="rainbow-border"></span>
          </a>
        </div>

        <div class="user-poll-actions-controls">
          <div class="user-poll-actions-controls-switch-wrapper">
            <div class="user-poll-actions-controls-switch-text">Visible</div>
            <mat-slide-toggle
              name="isVisible"
              class="yellow"
              (change)="visibilityChange($event, poll)"
              checked='{{poll.isVisible}}'
            >
            </mat-slide-toggle>
          </div>
          <img class="delete-icon" src="assets/images/polls/bin-icon.svg" alt="bin-icon" (click)="deletePoll(poll.id)">
        </div>
      </div>

    </div>
  </div>

</div>
