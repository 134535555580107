import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { UserSessionProvider } from '../../shared/user-session-provider';
import {
  EquityDealDTO,
  EquityDealServiceProxy,
  UsersServiceProxy,
} from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Referrers, ReferrersWithSum } from '../../models/referral.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
})
export class ReferralsComponent extends ComponentBase implements OnInit {
  public waitingDeals: boolean;
  public referrals: ReferrersWithSum[];
  public searchString: string;

  constructor(
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: EquityDealServiceProxy,
    private router: Router,
    private readonly serviceProxy: UsersServiceProxy
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.referrals = await this.serviceProxy
      .getReferrals()
      .pipe(
        map((i: Referrers[]) => {
          return i.map((k) => {
            return {
              ...k,
              rewardAmountSumm: k.referrals.reduce(
                (a, b) => BigInt(a) + BigInt(b.rewardAmount),
                BigInt(0)
              ),
              stackedAmountSumm: k.referrals.reduce(
                (a, b) => BigInt(a) + BigInt(b.stackedAmount),
                BigInt(0)
              ),
            };
          });
        })
      )
      .toPromise();
  }

  public async saveRefData(): Promise<void> {
    const csvString = this.convertObjectToCSV(this.referrals);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'referrals.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(typeof row[fieldName] === 'bigint' ? row[fieldName].toString() : row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n');
    return csv;
  }

  private generateTreeStructure(): any[] {
    return [];
  }
}
