import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AnalyticsServiceProxy, RegsteredUserDTO, TierUsersDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';

@Component({
  selector: 'app-analytics-new-user-registrations',
  templateUrl: './analytics-new-user-registrations.component.html',
  styleUrls: ['./analytics-new-user-registrations.component.scss']
})
export class AnalyticsNewUserRegistrationsComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() selectedSubComponent: 'new_user_registrations' | 'new_user_registrations_with_KYC' | 'referred_users';

  tiers: { index: number, name: string }[] = [
    {index: -1, name: 'None'},
    {index: 0, name: 'Copper Bull'},
    {index: 1, name: 'Bronze Bull'},
    {index: 2, name: 'Silver Bull'},
    {index: 3, name: 'Golden Bull'},
    {index: 4, name: 'Titanium Bull'},
    {index: 5, name: 'Platinum Bull'}
  ];


  isLoading: boolean = false;
  userRegistrations: RegsteredUserDTO[];
  
  filterForm: FormGroup;
  sortByColumn: string = '';
  sortDirection: SortDirection = '';
  searchValue: string = '';


  totalCount: number | null = null;
  pageSize: number = 25;
  page: number = 0;

  constructor(private formBuilder: FormBuilder, private router: Router, private analyticsServiceProxy: AnalyticsServiceProxy) {
    super();
  }
  
  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      tier: -2,
      dateFrom: this.getTwoMonthsBehind,
      dateTo: new Date(),
      kycApproved: 'all',
      dealsJoined: '',
      isReferred: 'all'
    });

    if(this.selectedSubComponent == 'new_user_registrations'){
      this.filterForm.get('isReferred').setValue('all');
      this.filterForm.get('kycApproved').setValue('all');
    }
    if(this.selectedSubComponent == 'new_user_registrations_with_KYC'){
      this.filterForm.get('isReferred').setValue('all');
      this.filterForm.get('kycApproved').setValue(true);
    }
    if(this.selectedSubComponent == 'referred_users'){
      this.filterForm.get('isReferred').setValue(true);
      this.filterForm.get('kycApproved').setValue('all');
    }
    
    this.fetchUserRegsitrations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedSubComponent){
      this.searchValue = '';
      if(this.selectedSubComponent == 'new_user_registrations'){
        this.filterForm.get('isReferred').setValue('all');
        this.filterForm.get('kycApproved').setValue('all');
      }
      if(this.selectedSubComponent == 'new_user_registrations_with_KYC'){
        this.filterForm.get('isReferred').setValue('all');
        this.filterForm.get('kycApproved').setValue(true);
      }
      if(this.selectedSubComponent == 'referred_users'){
        this.filterForm.get('isReferred').setValue(true);
        this.filterForm.get('kycApproved').setValue('all');
      }

      this.fetchUserRegsitrations(); 
    }
  }

  fetchUserRegsitrations(){
    this.isLoading = true;

    let kycApproved = this.filterForm.get('kycApproved').value;
    let isReferred = this.filterForm.get('isReferred').value;
    if(kycApproved == 'all'){
      kycApproved = '';
    }
    if(isReferred == 'all'){
      isReferred = '';
    }

    this.analyticsServiceProxy.GetRegisteredUsers(this.searchValue, kycApproved, isReferred, this.filterForm.get('dateFrom').value,
    this.filterForm.get('dateTo').value, this.filterForm.get('tier').value, this.filterForm.get('dealsJoined').value, this.pageSize, this.page)
      .subscribe(result => {
        this.userRegistrations = result.items;
        this.totalCount = result.totalItems;
        this.isLoading = false;
      },
        error => {
          this.showErrorModal(error.message || JSON.parse(error.response).message);
        }, () => {
          this.isLoading = false;
        });
  }

  pageChanged($event: any): void {
    this.page = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.fetchUserRegsitrations();
  }

  get getTwoMonthsBehind() {
    const twoMonthsInMs = 1000 * 60 * 60 * 24 * 30 * 2; // Milliseconds in 2 months
    const newDate = new Date(new Date().getTime() - twoMonthsInMs);
    newDate.setDate(Math.min(newDate.getDate(), newDate.getMonth() === 0 ? 28 : 31)); // Handle edge cases for month length
    return newDate;
  }
  
  sortData(sort: Sort) {
  }

  public async saveData(): Promise<void> {
    const allData = await this.analyticsServiceProxy.GetRegisteredUsers(this.searchValue, this.filterForm.get('kycApproved').value, this.filterForm.get('isReferred').value, this.filterForm.get('dateFrom').value,
    this.filterForm.get('dateTo').value, this.filterForm.get('tier').value, this.filterForm.get('dealedJoined').value, this.pageSize, this.page).toPromise();

    const csvString = this.convertObjectToCSV(allData.items);
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'user_registrations.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv;
  }

  navigateToUserDetails(userAddress: string) {
    this.router.navigate(['/user-details'], { queryParams: { userid: userAddress } });
  }

  formatDate(date: Date): string {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    var convertedDate = new Date(date);
    return `${days[convertedDate.getDay()]} ${months[convertedDate.getMonth()]} ${convertedDate.getDate()} ${convertedDate.getFullYear()}`;
  }

  formatTimeToGMT(date: Date): string {
    var convertedDate = new Date(date);
    const hours = convertedDate.getHours().toString().padStart(2, '0');
    const minutes = convertedDate.getMinutes().toString().padStart(2, '0');
    const seconds = convertedDate.getSeconds().toString().padStart(2, '0');
  
    const gmtOffset = convertedDate.getTimezoneOffset() / 60; // Get offset in hours
    const offsetSign = gmtOffset >= 0 ? '+' : '-';
    const offsetHours = Math.abs(gmtOffset).toString().padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds} GMT ${offsetSign}${offsetHours}00`;
  }
}
