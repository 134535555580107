<div class="create-user-poll-page">
  <form #createUserPollForm="ngForm" class="create-user-poll-form">
    <div class="create-user-poll-blocks-wrapper">
      <div class="visibility-block">
        <p class="visibility-block-text">Poll is Visible For Users</p>
        <mat-slide-toggle
          name="isVisible"
          [(ngModel)]="isVisible"
          [checked]="isVisible"
          class="yellow"
        >
        </mat-slide-toggle>
      </div>

      <div class="context-block">
        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>Notification Title</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="title"
                 name="title"
                 required
          >
        </mat-form-field>

        <div class="quill-field {{modelRef.touched && !description ? 'invalid' : ''}}">
          <mat-label>Notification Text</mat-label>
          <ckeditor #modelRef="ngModel" [editor]="Editor" [config]="config" [(ngModel)]="description" name="description" [required]="true"></ckeditor>
          <!--<quill-editor [modules]="quillConfig" (onEditorCreated)="getEditorInstance($event)" placeholder="" [(ngModel)]="description" name="description" #modelRef="ngModel"
                        [styles]="quillStyles" [required]="true" #quillEditor (onContentChanged)="addHttpsToLinks(quillEditor)" trackChanges="all">
          </quill-editor>-->
        </div>

      </div>

      <div class="answers-block">
        <div class="answers-block-header">
          <p class="answers-block-header-text">#</p>
          <p class="answers-block-header-text">Answer</p>
        </div>

        <div class="answers-block-body">
          <div class="answer-item" *ngFor="let option of options; let i = index">
            <ng-container *ngIf="!option.editing">
              <p class="answer-item-number">{{i + 1}}</p>
              <p class="answer-item-text">{{option.option}}</p>
              <div class="answer-item-actions">
                <div class="action-btn" (click)="editOption(option)">
                  <i class="icon icomoon-edit"></i>
                </div>
                <div class="action-btn" (click)="removeOption(option)">
                  <i class="icon icomoon-close"></i>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="option.editing">
              <p class="answer-item-number">{{i + 1}}</p>
              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Edit answer</mat-label>
                <input matInput [(ngModel)]="option.editedOption" [ngModelOptions]="{standalone: true}" required>
              </mat-form-field>

              <!--<input [(ngModel)]="option.option" [ngModelOptions]="{standalone: true}">-->
              <div class="answer-item-actions">
                <button mat-raised-button class="btn-outline-grey action-btn" (click)="saveOption(option)" [disabled]="!option.editedOption">
                  <i class="icon icomoon-checkmark"></i>
                </button>
              </div>
            </ng-container>
          </div>

          <div class="answer-item add-option" *ngIf="addingOption">
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Add answer</mat-label>
              <input matInput [(ngModel)]="newOption" [ngModelOptions]="{standalone: true}" required>
            </mat-form-field>


            <div class="answer-item-actions">
              <button mat-raised-button class="btn-outline-grey action-btn" (click)="saveNewOption()" [disabled]="!newOption.length">
                <i class="icon icomoon-checkmark"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="answers-block-footer">
          <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100" (click)="addingOption = true">
            <i class="icon icomoon-plus"></i>
            Answer
            <span class="rainbow-border"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="create-user-poll-form-action-btns">
      <button mat-stroked-button class="btn-icon btn-icon-left" (click)="cancelPollCreation()">
        <i class="icon icomoon-close"></i>
        Cancel
      </button>
      <button mat-raised-button color="primary" class="btn-icon btn-icon-left"
         [disabled]="!createUserPollForm.form.valid || !options.length" (click)="createPoll()">
        <i class="icon icomoon-checkmark"></i>
        Publish
        <span class="rainbow-border"></span>
      </button>
    </div>
  </form>
</div>
