import {Component, OnInit} from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {
  ClaimingDealServiceProxy,
  DealDTO,
  DealEventsCollectProcessStatus,
  DealPaymentsDTO,
  DealServiceProxy,
  DealUpdateDTO,
  DealWhiteListUpdateDTO,
  ReloadDealEventsStatusDTO
} from '../../service-proxies/service-proxies';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkNamePipe} from '../../shared/pipes/networkName.pipe';
import BigNumber from 'bignumber.js';
import {environment} from '../../environments/environment';
import {QuillEditorComponent, QuillModules} from "ngx-quill";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'edit-deal',
  templateUrl: './edit-deal.component.html',
  styleUrls: ['./edit-deal.component.scss'],
})
export class EditDealComponent extends ComponentBase implements OnInit {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private claimingDealServiceProxy: ClaimingDealServiceProxy,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    super();
  }

  waiting = false;
  account = '';

  isCollectWalletDeal = false;
  dealAddress: string;
  dealLastBlock: string;
  deal: DealDTO;
  name: string;
  description: string;
  emissionDescription: string;
  customPrice: string;
  userFriendlyCustomPrice: number;
  userFriendlySecondCustomPrice: number;
  fees: string;
  projectURL: string;
  logoURL: string;
  dealType: number;
  targetNetwork: number;
  visibleForUsers: boolean;
  enabledWhiteList: boolean;
  hideTgeDate: boolean;
  whiteListAddresses: string;
  customAllocations: string;
  claimURL: string;
  disclaimerText: string;
  disclaimer: string;
  showDisclaimer: boolean;

  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string;
  allowRefund: boolean;
  customTokenName!: string | undefined;
  investmentPercentage: number| undefined;
  tokenIconUrl: string | undefined;
  secondTokenCustomName: string | undefined;
  secondTokenIconUrl: string | undefined;
  secondTokenCustomPrice: string | undefined;
  secondTokenInvestmentPercentage: number | undefined;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  // #end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  rewardTokenName: string;
  minRewardTokenAmount: number;
  tokensPerETH: number;

  allTiers: any[] = [];
  allMainTiers: any[] = [];

  newTierBlpAmount: number;
  newTierTicketSize: number;
  newTierAllocation: number = 0;
  newTierFeePercent: number;

  updateTierIndex = -1;
  updateTierBlpAmount: number;
  updateTierTicketSize: number;
  updateTierAllocation: number = 0;
  updateTierFeePercent: number;

  specialTiersFeePercent: number;
  oldDeal: boolean = false;

  socialTwitter: string;
  socialMedium: string;
  socialYouTube: string;
  socialFacebook: string;
  socialTelegram: string;
  socialLinkedIn: string;
  socialInstagram: string;
  socialTelegramChannel: string;
  policy: string;

  isPreview:boolean;

  updateTimeTimerId: NodeJS.Timeout;
  now: number;
  dealTypes = [
    { id: 1, label: 'IMO' }
    /*{ id: 0, label: 'VC' },
    { id: 2, label: 'INO' }*/
  ];
  tokenDecimalShifts = [
    {name: 'Ether (10^1)', decimals: 18},
    {name: 'Finney (10^3)', decimals: 15},
    {name: 'Szabo (10^6)', decimals: 12},
    {name: 'Gwei (10^9)', decimals: 9},
    {name: 'MWei (10^12)', decimals: 6},
    {name: 'KWei (10^15)', decimals: 3},
    {name: 'Wei (10^18)', decimals: 0},
  ];
  selectedEventsDecimalShifts = 0;

  quillStyles = {
    'min-height': '100px'
  };
  quillConfig: QuillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['clean'],
      ['image'],
    ],
  };

  public Editor: any = Editor;
  public config: CKEditor5.Config = {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    link: {
      defaultProtocol: 'http://'
    },
    mediaEmbed: {
      previewsInData: true
    }
  }

  editor: any;


  networkTypes: any = environment.bsc.networkTypes;
  dealEvents: string;
  eventExtractionsStatus: string;
  eventExtractionsStatusDto: ReloadDealEventsStatusDTO;
  registrations: any[];
  payments: DealPaymentsDTO[];

  async ngOnInit() {
    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

    }, 1000);

    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params.address;
        console.log(`deal address: ${this.dealAddress}`);
        this.getDealDTO();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    // this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    // });

    // this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    // });
    this.account = this.userSessionProvider.linkedWallet;
    this.updateDealContractData();

    await this.getApprovedInteres();
    await this.getPayments();
  }

  downloadData():void{
    const csvString = this.convertObjectToCSV(this.registrations.map(i=>({address:i.userAddress, approvedAmount: i.amount, tier: i.userTier, date:  this.datePipe.transform(i.creationDateTime, 'YYYY-MM-dd HH:mm:ss') })));
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'approved_interest_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(typeof row[fieldName] === 'bigint' ? row[fieldName].toString() : row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n');
    return csv;
  }

  getEditorInstance(handler: QuillEditorComponent): void {
    this.editor = handler;
    const toolbar = this.editor.getModule('toolbar');
    const image = toolbar?.addHandler('image', () => {
      this.handleImage();
    });
    image.handler = this.handleImage.bind(this);
  }

  handleImage(): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024; // 2 MB
      if (file && file.size > maxSize) {
        alert(`Image size exceeds the maximum allowed size of 2 MB`);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const range = this.editor.getSelection(true);
          const index = range.index + range.length;
          this.editor.insertEmbed(index, 'image', reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  addHttpsToLinks(editor: QuillEditorComponent): void {
    const links = editor.quillEditor.root.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      if (!links[i].attributes.href.value.startsWith('https:')) {
        if (links[i].attributes.href.value.startsWith('http://')) {
          links[i].href = links[i].attributes.href.value;
        }else {
          links[i].href = 'https://' + links[i].attributes.href.value;
        }
      }
    }
  }

  getDealDTO() {
    this.dealService.getByAddress(this.dealAddress).subscribe(result => {
      this.deal = result;
      this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
      this.rewardToken = this.deal.rewardToken;
      this.minRewardTokenAmount = this.deal.minRewardTokenAmount;
      this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
      this.name = this.deal.name;
      this.description = this.deal.description;
      this.hideTgeDate = this.deal.hideTgeDate;
      this.isPreview = this.deal.preview;
      this.disclaimerText = this.deal.disclaimerText;
      this.disclaimer = this.deal.disclaimer;
      this.showDisclaimer = this.deal.showDisclaimer;
      this.emissionDescription = this.deal.emissionDescription;
      this.fees = this.deal.fees;
      this.projectURL = this.deal.url;
      this.logoURL = this.deal.logoURL;
      this.dealType = this.deal.dealType;
      this.policy = this.deal.policy;
      this.visibleForUsers = this.deal.visibleForUsers;
      this.customPrice = this.deal.customPrice;
      let _customPrice = new BigNumber(result.customPrice);
      let _secondCustomPrice = new BigNumber(result.secondTokenCustomPrice);
      let _divideBy =  new BigNumber(10 ** result.paymentDecimal);
      this.enabledWhiteList = this.deal.enabledWhitelisting;
      this.whiteListAddresses = this.deal.whitelistingAddresses;

      this.socialTwitter = this.deal.twitterURL;
      this.socialMedium = this.deal.mediumURL;
      this.socialYouTube = this.deal.youTubeURL;
      this.socialFacebook = this.deal.facebookURL;
      this.socialTelegram = this.deal.telegramURL;
      this.socialLinkedIn = this.deal.linkedInURL;
      this.socialInstagram = this.deal.instagramURL;
      this.socialTelegramChannel = this.deal.telegramChannelURL;
      this.targetNetwork = this.deal.targetNetwork;
      this.claimURL = this.deal.claimURL;
      this.customTokenName = this.deal.customTokenName;
      this.userFriendlyCustomPrice = result.customPrice ? _customPrice.dividedBy(_divideBy).toNumber(): null;
      this.investmentPercentage = this.deal.investmentPercentage;
      this.tokenIconUrl = this.deal.tokenIconUrl;
      this.secondTokenCustomName = this.deal.secondTokenCustomName;
      this.secondTokenIconUrl = this.deal.secondTokenIconUrl;
      //this.secondTokenCustomPrice = this.deal.secondTokenCustomPrice;
      this.userFriendlySecondCustomPrice = result.secondTokenCustomPrice ? _secondCustomPrice.dividedBy(_divideBy).toNumber(): null;
      this.secondTokenInvestmentPercentage = this.deal.secondTokenInvestmentPercentage;

      console.log(this.deal);
      if (this.web3Service.chainIdNumber != this.deal.chainId) {
        const chainName = new NetworkNamePipe().transform(this.deal.chainId);
        this.showErrorModal(`Select ${chainName} network in metamask!`);
        this.router.navigate(['/deals']);
      }
    },
      error => {
        this.processServiceError(error);
      });
    this.showEventsExtractionStatus();
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = '';
    // this.balance = null;
    // console.log('eventLogout')
    // if (this.timerId) {
    //  console.log('clearInterval');
    //  clearInterval(this.timerId);
    // }
  }


  async updateDealContractData() {
    console.log(`Get contract data ${this.dealAddress}`);
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      // this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);

    console.log(`isCollectWalletDeal: ${this.isCollectWalletDeal}`);
    // TODO: if another chain isCollectWalletDeal = true
    if (!this.isCollectWalletDeal) {
      this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
      this.web3Service.GetContractName(this.rewardToken).then((resp) => {
        this.rewardTokenName = resp;
      });
    }

    if (this.isCollectWalletDeal) {
      this.rewardDecimals = 18;
    }
    else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));
    try {
      this.specialTiersFeePercent = this.percentFromInteger(parseInt(await this.web3Service.getDealSpecialTiersFeePercent(this.dealAddress)));
    }
    catch {}

    this.web3Service.getDealMaxDistributedTokenAmount(this.dealAddress).then((resp) => {
      this.maxDistributedTokenAmount = resp;
      this.maxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(-this.rewardDecimals).toFixed();
    });

    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    this.allTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tiersLength; i++) {
      var tier = await this.web3Service.getDealTiers(this.dealAddress, i);
      if (!tier) {
        this.oldDeal = true;
        tier = await this.web3Service.getDealTiersOld(this.dealAddress, i);
      }
      console.log(tier);
      if (tier) {
        this.allTiers.push(tier);
      }

    }
    this.updateMainTiersContractData();

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    const vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == '0x0000000000000000000000000000000000000000' ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }

  async updateMainTiersContractData() {
    console.log('updateMainTiersContractData');

    this.allMainTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealLockupsTiers(i);
      console.log(tier);
      this.allMainTiers.push(tier);
    }
    if (this.allMainTiers.length > this.allTiers.length) {
      console.log('newTierBlpAmount');
      this.newTierBlpAmount = this.toNumberFromWei(this.allMainTiers[this.allTiers.length].blpAmount, 18);
    }

  }

  async saveNewTimeClick(): Promise<void> {
    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateTime(this.dealAddress, this.account, this.startTimestamp, this.finishTimestamp, this.startClaimTimestamp);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      // dialogRef.close();
      // this._alertSrv.show('Confirmed transaction');
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async syncTimeDb(): Promise<void> {
    this.waiting = true;

    this.waiting = true;

    this.dealService.syncTimestamp(this.dealAddress)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
          this.getDealDTO();
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateDealDb(): Promise<void> {
    this.waiting = true;

    const data: DealUpdateDTO = new DealUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.name = this.name;
    data.description = this.description;
    data.emissionDescription = this.emissionDescription;
    data.fees = this.fees;
    data.url = this.projectURL;
    data.logoURL = this.logoURL;
    data.dealType = this.dealType;
    data.policy = this.policy;
    data.visibleForUsers = this.visibleForUsers;
    data.twitterURL = this.socialTwitter;
    data.mediumURL = this.socialMedium;
    data.youTubeURL = this.socialYouTube;
    data.facebookURL = this.socialFacebook;
    data.telegramURL = this.socialTelegram;
    data.linkedInURL = this.socialLinkedIn;
    data.instagramURL = this.socialInstagram;
    data.telegramChannelURL = this.socialTelegramChannel;
    data.targetNetwork = this.targetNetwork;
    data.hideTgeDate = this.hideTgeDate;
    data.showDisclaimer = this.showDisclaimer;
    data.disclaimerText = this.disclaimerText;
    data.disclaimer = this.disclaimer;
    data.claimURL = this.claimURL;
    data.preview = this.isPreview;
    data.customTokenName = this.customTokenName;
    if(this.userFriendlyCustomPrice){
      let multiplyBy =  new BigNumber(10 ** this.paymentDecimal);
      data.customPrice = new BigNumber(this.userFriendlyCustomPrice).multipliedBy(multiplyBy).toString();
    }
    data.investmentPercentage = this.investmentPercentage ? Number(this.investmentPercentage) : null;
    data.tokenIconUrl = this.tokenIconUrl;

    data.secondTokenCustomName = this.secondTokenCustomName;
    if(this.userFriendlySecondCustomPrice){
      let multiplyBy =  new BigNumber(10 ** this.paymentDecimal);
      data.secondTokenCustomPrice = new BigNumber(this.userFriendlySecondCustomPrice).multipliedBy(multiplyBy).toString();
    }
    data.secondTokenInvestmentPercentage = this.secondTokenInvestmentPercentage ? Number(this.secondTokenInvestmentPercentage) : null;
    data.secondTokenIconUrl = this.secondTokenIconUrl;

    console.log('update data', {data});
    this.dealService.update(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async UpdateRewardTokenSymbol(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenSymbol(this.dealAddress, this.rewardTokenSymbol)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async UpdateMinRewardTokenAmount(): Promise<void> {
    this.waiting = true;

    this.dealService.updateMinRewardTokenAmount(this.dealAddress, this.minRewardTokenAmount)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelist(): Promise<void> {
    this.waiting = true;

    const data: DealWhiteListUpdateDTO = new DealWhiteListUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.enabledWhiteList = this.enabledWhiteList;
    data.addresses = this.whiteListAddresses;

    this.waiting = true;

    this.dealService.updateWhiteList(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async addCustomAllocations(): Promise<void> {
    let users = [];
    let tiers = [];
    let raises = [];
    let eventSource: any;

    try {
      let strings = this.customAllocations.split("\n");

      for (let i = 0; i < strings.length; i++) {
        let row = strings[i].split(",");
        users.push(row[0]);
        tiers.push(row[1]);
        raises.push(row[2]);
      }

      eventSource = this.web3Service.dealAddCustomAllocations(this.dealAddress, this.account, users, tiers, raises);
    }
    catch {
      this.showErrorModal('Invalid data');
      return;
    }

    this.waiting = true;

    try {
      await eventSource.receipt$.toPromise();
      this.showInfoModal('Confirmed transaction');
    } catch (err: any) {
      this.showErrorModal(err.reason || err.message || 'Error');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }

  async removeCustomAllocations(): Promise<void> {
    let eventSource: any;

    try {
      let users = this.customAllocations.split("\n");
      eventSource = this.web3Service.dealRemoveCustomAllocations(this.dealAddress, this.account, users);
    }
    catch {
      this.showErrorModal('Invalid data');
      return;
    }

    this.waiting = true;

    try {
      await eventSource.receipt$.toPromise();
      this.showInfoModal('Confirmed transaction');
    } catch (err: any) {
      this.showErrorModal(err.reason || err.message || 'Error');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }


  async createVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealCreateVestingContract(
      this.account, this.dealAddress, this.vestingPercent, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async saveVestingPercentClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateVestingPercent(this.account, this.dealAddress, this.vestingPercent);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async updateVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingUpdateVestingTime(
      this.account, this.vestingContractAddress, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async updateSpecialTiersFeePercent(): Promise<void> {
    this.waiting = true;

    const contractEventsSource = this.web3Service.dealUpdateSpecialTiersFeePercent(
      this.account, this.dealAddress, this.percentToInteger(this.specialTiersFeePercent));

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      //console.log(response);

      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  select(index: number) {
    this.updateTierIndex = index;
    this.updateTierBlpAmount = this.toNumberFromWei(this.allTiers[index].blpAmount, 18);
    this.updateTierTicketSize = this.toNumberFromWei(this.allTiers[index].ticketSize, this.deal?.paymentDecimal);
    //this.updateTierAllocation = this.toNumberFromWei(this.allTiers[index].allocation, this.deal?.rewardDecimal);
    this.updateTierFeePercent = this.percentFromInteger(this.allTiers[index].feePercent);
  }

  async updateTierClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    let contractEventsSource;

    if (this.oldDeal) {
      contractEventsSource = this.web3Service.dealUpdateTierOld(
        this.account, this.dealAddress, this.updateTierIndex,
        this.updateTierBlpAmount, this.updateTierTicketSize, this.updateTierAllocation,
        this.deal.paymentDecimal, this.deal.rewardDecimal);
    }
    else {
      contractEventsSource = this.web3Service.dealUpdateTier(
        this.account, this.dealAddress, this.updateTierIndex,
        this.updateTierBlpAmount, this.updateTierTicketSize, this.updateTierAllocation,
        this.percentToInteger(this.updateTierFeePercent), this.deal.paymentDecimal, this.deal.rewardDecimal);
    }

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateTierIndex = -1;
      this.updateTierBlpAmount = null;
      this.updateTierTicketSize = null;
      //this.updateTierAllocation = null;
      this.updateTierFeePercent = null;
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async addTierClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    let contractEventsSource;

    if (this.oldDeal) {
      contractEventsSource = this.web3Service.dealAddTierOld(
        this.account, this.dealAddress, this.newTierBlpAmount, this.newTierTicketSize, this.newTierAllocation,
        this.deal.paymentDecimal, this.deal.rewardDecimal);
    }
    else {
      contractEventsSource = this.web3Service.dealAddTier(
        this.account, this.dealAddress, this.newTierBlpAmount, this.newTierTicketSize, this.newTierAllocation,
        this.percentToInteger(this.newTierFeePercent), this.deal.paymentDecimal, this.deal.rewardDecimal);
    }

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      //this.newTierAllocation = null;
      this.newTierFeePercent = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawFundsClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealWithdrawFunds(this.account, this.dealAddress);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      //this.newTierAllocation = null;
      this.newTierFeePercent = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawNotSoldTokensClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealWithdrawNotSoldTokens(this.account, this.dealAddress);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      //this.newTierAllocation = null;
      this.newTierFeePercent = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  async extractDealPaymentEventsClick(): Promise<void>  {

    this.dealService.collectDealEvents(this.dealAddress, this.dealLastBlock)
      .subscribe(
        result => {
          this.showInfoModal('Events Collected');
        },
        error => {
          this.processServiceError(error);
        }
      );
  }

  async updateRewardAddress(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenAddress(this.dealAddress, this.rewardToken)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async getApprovedInteres(): Promise<void>{
    this.registrations = await this.dealService.getRegistrationsForDeal(this.dealAddress).toPromise();
    console.log('registrations', this.registrations)
  }

  async getPayments(): Promise<void>{
    this.payments = await this.dealService.getPaymentsForDeal(this.dealAddress).toPromise();
  }

  async saveMaxDistributedTokenAmountClick(): Promise<void> {
    this.waiting = true;

    const stringMaxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toFixed();
    const contractEventsSource = this.web3Service.dealUpdateMaxDistributedTokenAmount(this.dealAddress, this.account, stringMaxDistributedTokenAmount);

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async syncMaxDistributedTokenAmountDb(): Promise<void> {
    this.waiting = true;

    const stringMaxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toFixed();
    this.dealService.updateMaxDistributedTokenAmount(this.dealAddress, stringMaxDistributedTokenAmount)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  showDealEventsClick() {
    this.dealService.dealEventsToCsv(this.dealAddress).subscribe(result => {
      this.dealEvents = result;
    },
      error => {
        this.processServiceError(error);
      });
  }

  public async saveData(): Promise<void> {
    this.dealService.dealEventsToCsvForDownload(this.dealAddress, this.selectedEventsDecimalShifts).subscribe(result => {
      const csvString = result;
      const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'deal_events.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
      error => {
        this.processServiceError(error);
      });
  }

  showEventsExtractionStatus() {
    this.dealService.getReloadDealEventsStatus(this.dealAddress).subscribe(result => {
      this.eventExtractionsStatusDto = result;
      this.eventExtractionsStatus = 'Status =' + DealEventsCollectProcessStatus[result.status].toString() +
        '\n% of completion = ' + result.completionPercent +
        '\n Start time = ' + result.startTimestamp +
        '\n End time = ' + result.endTimestamp;
     },
      error => {
        this.processServiceError(error);
      });
  }

  isEventCollectionInProgress() {
    return this.eventExtractionsStatusDto?.status == DealEventsCollectProcessStatus.InProgress;
  }

  getMaxTimeStampAfterMinutes(delayMinutes: number){
    // return this.deal.startTime + (delayMinutes + 24) * 60 * 2;
    return this.deal.startTime + (delayMinutes * 60)
  }

  get minutesAfterClaimTime(): number[]{
    var lastPaymentTimeStamp = Math.max(...this.payments.map(p => p.timeStamp));

    let intervals = [0, 1, 5, 15, 30, 60, 180, 360, 720, 1440, 2880];

    while(lastPaymentTimeStamp > this.getMaxTimeStampAfterMinutes(intervals[intervals.length - 1])){
      intervals.push(intervals[intervals.length - 1] + 1440) // add one day to the range
    }
    return intervals;
  }

  GetShortTimeStringFromMinute(noOfMinutes: number){

    if(noOfMinutes < 60 ){
      return `${noOfMinutes}m`;
    }
    return `${Math.floor(noOfMinutes / 60)}h`;
  }

  formatNumber(number: number) {
    if(number % 1 == 0){
      return number;
    }
    return number.toFixed(1);
  }

  public percentFromInteger(number: number): number {
    return isNaN(number) ? 0 : parseFloat((number / 100.0).toFixed(2));
  }

  public percentToInteger(number: number): number {
    return isNaN(number) ? 0 : Math.round(number * 100);
  }

  GetPaymentTotalForTier(tierIndex: number, minutesAfterClaimStart: number): number{
    let maxTimeTimeStampFilter = this.getMaxTimeStampAfterMinutes(minutesAfterClaimStart);
    let paymentsSum = this.payments.filter(p => maxTimeTimeStampFilter >= p.timeStamp && (p.userTierIndex ?? -1) == tierIndex).reduce((accumulator, currentValue) => accumulator + new BigNumber(currentValue.payAmount).shiftedBy(12).toNumber(), 0);
    return paymentsSum;
  }

  GetPaymentTotalPercentage(minutesAfterClaimStart: number){
    let maxTimeTimeStampFilter = this.getMaxTimeStampAfterMinutes(minutesAfterClaimStart);
    var totalPaymentsAfterMinutes = this.payments.filter(p => maxTimeTimeStampFilter >= p.timeStamp).reduce((accumulator, currentValue) => accumulator + new BigNumber(currentValue.payAmount).shiftedBy(12).toNumber(), 0);

    let totalRaised = new BigNumber(this.deal.maxDistributedTokenAmount).shiftedBy(-this.deal.rewardDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.deal.paymentDecimal).toNumber();
    return this.formatNumber(totalPaymentsAfterMinutes * 100 / totalRaised);

  }
}
