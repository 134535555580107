<div class="modal-header">
  <i class="icon icomoon-down"></i>
  <h5 class="modal-title">Create snapshot</h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body stack">
  <!-- <button class="address-btn --copy w-100 mb-4">
    <span>{{account}}</span>
  </button> -->

  <div class="d-md-flex align-items-center">  
    <mat-form-field appearance="fill" hideRequiredMarker>
      <mat-label>Locker</mat-label>
      <input matInput name="locker" [(ngModel)]="locker">
    </mat-form-field>
  </div>

  <div class="d-md-flex align-items-center">  
    <mat-form-field appearance="fill" hideRequiredMarker>
      <mat-label>Staking Pools</mat-label>
      <textarea matInput name="stakingPools" [(ngModel)]="stakingPools"></textarea>
    </mat-form-field>
  </div>

  <div class="d-md-flex align-items-center mb-2">  
    <mat-slide-toggle class="snapshot-toggle" name="includeInactive" [(ngModel)]="includeInactive"></mat-slide-toggle>
    <div class="subtitle-1 me-auto">Add Inactive Users</div>
  </div>

  <div [ngClass]="!includeInactive ? 'disabled' : ''" class="d-md-flex align-items-center mb-4 snapshot-toggle-wrapper">  
    <mat-slide-toggle class="snapshot-toggle" name="includeWithoutKYC" [(ngModel)]="includeWithoutKYC"></mat-slide-toggle>
    <div class="subtitle-1 me-auto">Add Non-KYC users</div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <button mat-raised-button color="primary" class="w-100" [disabled]="isEmpty" (click)="save()">
        Create
      </button>
    </div>
    <div class="col-md-6">
      <a [mat-dialog-close]
         mat-stroked-button class="w-100"
         target="_blank">
        Cancel
      </a>
    </div>
  </div>

</div>
