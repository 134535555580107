import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { EquityDealDTO, EquityDealServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'equity-deals',
  templateUrl: './equity-deals.component.html',
  styleUrls: ['./equity-deals.component.scss'],
})

export class EquityDealsComponent extends ComponentBase implements OnInit {
  constructor(
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: EquityDealServiceProxy,
    private router: Router
  ) {
    super();
  }

  public deals: Array<EquityDealDTO> = [];

  waiting: boolean = false;
  account: string = '';
  waitingDeals: boolean = true;

  async ngOnInit() {
    await this.web3Service.initWeb3();

    this.dealService.getAll(this.web3Service.chainIdNumber)
      .subscribe(result => {
        this.deals = result;
        this.waitingDeals = false;
      },
        error => {
          this.processServiceError(error);
        });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToEditDeal(id: number): void {
    this.router.navigate(['equity-deal'],{ queryParams: { id: id }});
  }


  showWarningDeleteModal(id: number): void {
    Swal.fire({
      title: 'Do you want to delete?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#e60000',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteDeal(id);
      }
    });
  }

  deleteDeal(dealId: number): void {
    this.waiting = true;
    this.dealService.delete(dealId).subscribe(
      result => {
        this.waiting = false;
        this.showInfoModal('Saved');
        this.dealService.getAll(this.web3Service.chainIdNumber)
          .subscribe(result => {
              this.deals = result;
              this.waitingDeals = false;
            },
            error => {
              this.processServiceError(error);
            });
      },
      error => {
        this.waiting = false;
        console.log(error);
        this.showErrorModal(error.error?.title || error.message);
      }
    );
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
