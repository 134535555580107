<header class="dashboard-header">
  <div class="dashboard-header__title">
    <h1 class="header-title" [ngSwitch]="routerPath">
      <span *ngSwitchCase="'/login'">Login</span>
      <span *ngSwitchCase="'/users'"><i class="icomoon-users"></i> Users</span>
      <a href="/wallet-approval" class="back-to-prev" *ngSwitchCase="'/user-details'">User Details</a>
      <span *ngSwitchCase="'/admins'"><i class="icomoon-users"></i> Admins</span>
      <span *ngSwitchCase="'/change-wallets'"><i class="icomoon-address-book"></i>Wallet Changes</span>
      <span *ngSwitchCase="'/penalties'"><i class="icomoon-chart-down"></i> Penalties</span>
      <span *ngSwitchCase="'/tiers'"><i class="icomoon-tiers"></i>Tiers</span>
      <span *ngSwitchCase="'/settings'"><i class="icomoon-warning"></i> Settings</span>

      <span *ngSwitchCase="'/staking'">Staking pool</span>
      <span *ngSwitchCase="'/farming'">Farming pool</span>
      <span class="back-to-prev" *ngSwitchCase="'/edit-pool'" (click)="backToPrev()">Edit Pool</span>
      <span class="back-to-prev" *ngSwitchCase="'/pool-detail'" (click)="backToPrev()">Pool Details</span>
      <span *ngSwitchCase="'/create-pool'">Create Pool</span>

      <span *ngSwitchCase="'/deals'">Deals</span>
      <span *ngSwitchCase="'/equity-deals'">Equity Deals</span>
      <span *ngSwitchCase="'/create-wallet-deal'">Create Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/equity-deal'" (click)="backToPrev()">Equity Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/edit-deal'" (click)="backToPrev()">Edit Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/deal-detail'" (click)="backToPrev()">Deal Details</span>
      <span *ngSwitchCase="'/voting'">Voting</span>
      <span *ngSwitchCase="'/create-poll'">Create Poll</span>
      <span *ngSwitchCase="'/edit-poll'">Edit Poll</span>

      <span *ngSwitchCase="'/analytics'">Analytics</span>
      <span class="back-to-prev" *ngSwitchCase="'/analytics/user-details'" (click)="backToPrev()">User Details</span>
      <span class="back-to-prev" *ngSwitchCase="'analytics/new-registrations'" (click)="backToPrev()">New Registrations</span>
      <span class="back-to-prev" *ngSwitchCase="'/analytics/tier-changes'" (click)="backToPrev()">Tier Changes</span>


      <span *ngSwitchCase="'/statistics'">Statistics</span>

      <span class="back-to-prev" *ngSwitchCase="'/country-details'" (click)="backToPrev()">User Registrations By Country</span>
    </h1>
  </div>

  <app-wallet></app-wallet>

  <div class="dashboard-header__select">
    <button mat-button class="dropdown-button __select-network" [matMenuTriggerFor]="networks">
      <img src="{{ web3Service.chainIdNumber | imgNetworkPipe }}"/>
      {{getNetworkName(web3Service.chainIdNumber)}}
    </button>
    <mat-menu #networks="matMenu" class="select-network-menu">
      <button  mat-menu-item *ngFor="let networkId of availableNetworks" (click)="selectNetworkClick(networkId)">
        <img src="{{ networkId | imgNetworkPipe }}"/>
        {{getNetworkName(networkId)}}
      </button>
    </mat-menu>
    <button *ngIf="authenticated" (click)="showNotificationDlg()" mat-icon-button matTooltip="Add notification" class="p-3 ms-3">
      <img src="../../assets/svg/notification.svg"/>
    </button>
  </div>
</header>
