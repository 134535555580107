<main class="dashboard-main" [ngClass]="{'loading-page': waiting}">
  <form #createDealForm="ngForm" (ngSubmit)="submitClick()" class="create-deal-form">

    <section class="dashboard-section pt-40">
      <div class="card p-4 mb-40">
        <div class="row">
          <div class="col-lg-12 mb-4 d-flex align-items-center">
            <div class="subtitle-1 me-2">Equity deal visible for users</div>
            <mat-slide-toggle
              name="visibleForUsers"
              [(ngModel)]="deal.visibleForUsers"
            >
            </mat-slide-toggle>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="deal.name" name="name" required>
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Investment URL</mat-label>
              <input matInput [(ngModel)]="deal.investmentURL" name="investmentURL" required>
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Image URL</mat-label>
              <input matInput [(ngModel)]="deal.imgUrl" name="imgUrl" required>
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Logo URL</mat-label>
              <input matInput [(ngModel)]="deal.logoUrl" name="logoUrl">
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Total raise (with currency)</mat-label>
              <input matInput [(ngModel)]="deal.totalRaise" name="totalRaise">
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Signing Deadline</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="deal.deadline" name="deadline">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="form-field">
              <mat-label>Wire Deadline</mat-label>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="deal.wireDeadline" name="wireDeadline">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field {{modelRef.touched && !deal.summary ? 'invalid' : ''}}">
              <mat-label>About *</mat-label>
              <ckeditor #modelRef="ngModel" [editor]="Editor" [config]="config" [(ngModel)]="deal.summary" name="summary"></ckeditor>
            </div>
           <!-- <div class="quill-field {{modelRef.touched && !deal.summary ? 'invalid' : ''}}">
              <mat-label>About *</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.summary" name="summary" #modelRef="ngModel"
                            [styles]="quillStyles" [required]="true" #quillSummary (onContentChanged)="addHttpsToLinks(quillSummary)">
              </quill-editor>
              </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Metrics</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.metrics" name="metrics"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Metrics</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.metrics" name="metrics"
                            [styles]="quillStyles" #quillMetrics (onContentChanged)="addHttpsToLinks(quillMetrics)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Market</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.market" name="market"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Market</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.market" name="market"
                            [styles]="quillStyles" #quillMarket (onContentChanged)="addHttpsToLinks(quillMarket)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Media</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.media" name="media"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Media</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.media" name="media"
                            [styles]="quillStyles" #quillMedia (onContentChanged)="addHttpsToLinks(quillMedia)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>The Problem & Our Solution</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.problemAndSolution" name="problemAndSolution"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>The Problem & Our Solution</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.problemAndSolution" name="problemAndSolution"
                            [styles]="quillStyles" #quillProblemAndSolution (onContentChanged)="addHttpsToLinks(quillProblemAndSolution)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>How Do We Make Money?</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.howWeMakeMoney" name="howWeMakeMoney"></ckeditor>
            </div>
           <!-- <div class="quill-field">
              <mat-label>How Do We Make Money?</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.howWeMakeMoney" name="howWeMakeMoney"
                            [styles]="quillStyles" #quillHowWeMakeMoney (onContentChanged)="addHttpsToLinks(quillHowWeMakeMoney)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Team</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.team" name="team"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Team</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.team" name="team"
                            [styles]="quillStyles" #quillTeam (onContentChanged)="addHttpsToLinks(quillTeam)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Advisors</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.advisors" name="advisors"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Advisors</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.advisors" name="advisors"
                            [styles]="quillStyles" #quillAdvisors (onContentChanged)="addHttpsToLinks(quillAdvisors)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Investors</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.investors" name="investors"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Investors</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.investors" name="investors"
                            [styles]="quillStyles" #quillInvestors (onContentChanged)="addHttpsToLinks(quillInvestors)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Reasons to Invest</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.reasonsToInvest" name="reasonsToInvest"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Reasons to Invest</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.reasonsToInvest" name="reasonsToInvest"
                            [styles]="quillStyles" #quillReasonsToInvest (onContentChanged)="addHttpsToLinks(quillReasonsToInvest)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>Rewards</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.rewards" name="rewards"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>Rewards</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.rewards" name="rewards"
                            [styles]="quillStyles" #quillRewards (onContentChanged)="addHttpsToLinks(quillRewards)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field {{modelRef.touched && !deal.terms ? 'invalid' : ''}}">
              <mat-label>Terms *</mat-label>
              <ckeditor #modelRef="ngModel" [editor]="Editor" [config]="config" [(ngModel)]="deal.terms" name="terms"></ckeditor>
            </div>
            <!--<div class="quill-field {{modelRef.touched && !deal.terms ? 'invalid' : ''}}">
              <mat-label>Terms *</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.terms" name="terms" #modelRef="ngModel"
                            [styles]="quillStyles" [required]="true" #quillTerms (onContentChanged)="addHttpsToLinks(quillTerms)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field {{modelRef.touched && !deal.disclaimers ? 'invalid' : ''}}">
              <mat-label>Disclaimers *</mat-label>
              <ckeditor #modelRef="ngModel" [editor]="Editor" [config]="config" [(ngModel)]="deal.disclaimers" name="disclaimers"></ckeditor>
            </div>
            <!--<div class="quill-field {{modelRef.touched && !deal.disclaimers ? 'invalid' : ''}}">
              <mat-label>Disclaimers *</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.disclaimers" name="disclaimers" #modelRef="ngModel"
                            [styles]="quillStyles" [required]="true" #quillDisclaimers (onContentChanged)="addHttpsToLinks(quillDisclaimers)"></quill-editor>
            </div>-->
          </div>

          <div class="col-lg-12 mb-4 mb-lg-0">
            <div class="quill-field">
              <mat-label>FAQ</mat-label>
              <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="deal.faq" name="faq"></ckeditor>
            </div>
            <!--<div class="quill-field">
              <mat-label>FAQ</mat-label>
              <quill-editor [modules]="quillConfig" placeholder="" [(ngModel)]="deal.faq" name="faq"
                            [styles]="quillStyles" #quillFaq (onContentChanged)="addHttpsToLinks(quillFaq)"></quill-editor>
            </div>-->
          </div>

        </div>
      </div>
    </section>

    <section class="dashboard-section py-4 mt-lg-auto">
      <button type="submit"
              class="w-100"
              mat-raised-button
              color="primary"
              [ngClass]="{'wait': waiting}"
              [disabled]="!createDealForm.form.valid || waiting">
        {{editing ? 'Update' : 'Create'}} Equity Deal
        <span class="rainbow-border"></span>
      </button>
    </section>
  </form>
</main>
