<div class="card mb-4">
    <div class="row p-4">
      <div class="col">
        <mat-form-field class="white-label">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label >Search</mat-label>
            <input placeholder="Search by email or wallet address"  matInput [(ngModel)]="searchValue" value="">
          </mat-form-field>
      </div>
      <div class="col-3">
        <button type="button" mat-raised-button class="bg-success" [disabled]="isLoading" (click)="fetchUserTierChanges()">Search</button>
        <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base m-3"><img (click)="saveData()" src="../../assets/svg/download-solid.svg"/></button>
      </div>
    </div>
  </div>


  <div class="card">
    <form [formGroup]="filterForm" class="p-4">
        <p >Filter by:</p>
        <div class="row p-3">
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Previous Tier</mat-label>
                    <mat-select formControlName="previousTier">
                        <mat-option [value]="-2">Show All</mat-option>
                        <mat-option *ngFor="let tier of tiers" [value]="tier.index">
                            {{tier.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Current Tier</mat-label>
                    <mat-select formControlName="currentTier">
                        <mat-option [value]="-2">Show All</mat-option>
                        <mat-option *ngFor="let tier of tiers" [value]="tier.index">
                            {{tier.index| nameTierPipe}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="dateFrom" placeholder="Start date">
                        <input matEndDate formControlName="dateTo" placeholder="End date">
                    </mat-date-range-input>

                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

            </div>
            <div class="col-12">
                <button (click)="fetchUserTierChanges()" type="button" mat-raised-button class="bg-success w-100" [disabled]="isLoading">
                    <mat-icon>search</mat-icon>
                    Apply Filter
                </button>
            </div>
        </div>
    </form>
</div>

<div class="card mt-3">
    <table class="table table-responsive" matSort (matSortChange)="sortData($event)" [matSortActive]="sortByColumn"
        [matSortDirection]="sortDirection">
        <thead>
            <tr>
                <th mat-sort-header="registration">Registration</th>
                <th>Email</th>
                <th>Previous Tier</th>
                <th>Current Tier</th>
                <th>Wallet</th>
            </tr>
        </thead>
        <tbody [ngClass]="{'opacity-50': isLoading}">
            <tr *ngFor="let tierChange of tierChanges">
                <td><img [src]="tierChange.currentTier| imgTierPipe" class="tier-image"/> {{formatDate(tierChange.creationDateTime)}} <br> {{formatTimeToGMT(tierChange.creationDateTime)}}</td>
                <td>{{tierChange.email}}</td>
                <td>
                    {{tierChange.previousTier | nameTierPipe}}
                </td>
                <td>
                    {{tierChange.currentTier | nameTierPipe}}
                </td>
                <td>
                    <span>{{tierChange.ethAddress}}</span>
                    <a mat-icon-button class="m-3" matTooltip="Address Book" matTooltipPosition="above"
                        (click)="navigateToUserDetails(tierChange.ethAddress)">
                        <span class="icomoon-address-book"></span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="card mt-3">
    <mat-paginator [length]="totalCount"
                       [pageIndex]="page"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="pageChanged($event)"
                       aria-label="Select page">
        </mat-paginator>
</div>
