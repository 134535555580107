<div class="modal-header">
  <i class="icon icomoon-wallet"></i>
  <div class="modal-title">
    Connect to a wallet
  </div>
  <button class="btn-close" [mat-dialog-close]="true"></button>
</div>
<div class="modal-body">
  <div class="__wallet-list">
    <button (click)="unlockMetamaskClick()" mat-raised-button color="primary" class="__wallet-btn">
      <img class="__wallet-btn__img" src="../../assets/images/wallets/metamask.svg" alt="">
      <span class="__wallet-btn__title">Metamask</span>
      <span class="rainbow-border"></span>
    </button>

    <!--<button (click)="unlockMetamaskClick()" mat-raised-button color="primary" class="__wallet-btn">
      <img class="__wallet-btn__img" src="../../assets/images/wallets/binance.svg" alt="">
      <span class="__wallet-btn__title">Binance Wallet</span>
      <span class="rainbow-border"></span>
    </button>-->

    <button (click)="unlockWalletconnectClick()" mat-raised-button color="primary" class="__wallet-btn">
      <img class="__wallet-btn__img" src="../../assets/images/wallets/wallet_connect.svg" alt="">
      <span class="__wallet-btn__title">WalletConnect</span>
      <span class="rainbow-border"></span>
    </button>
  </div>
</div>
