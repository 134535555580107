import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgChainId',
})
export class ImgChainIdPipe implements PipeTransform {
  transform(chainId: number, isBlack: boolean = false): string {
    if (chainId == 56 || chainId == 97) return '/assets/svg/bsc.svg';
    if (chainId == 1 || chainId == 42) {
      return '/assets/images/chain/ethereum.svg';
    }
    if (chainId == 8453 || chainId == 84532) return '/assets/svg/Base.svg';
    if (chainId == 256 || chainId == 128) return '/assets/svg/heco.svg';
    return '';
  }
}
