<main class="dashboard-main">
  <section class="dashboard-section p-40" [ngClass]="{'loading-page': waiting}">
    <table class="tiers-table table w-100">
      <thead>
        <tr>
          <th>#</th>
          <th>Tier</th>
          <th>PACA Amount</th>
          <th>Lockup</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of allTiers; index as i;">
        <td data-title="Index">
          {{i}}
        </td>
        <td data-title="Image" matTooltip="{{i | nameTierPipe}}">
          <img [src]="i | imgTierPipe" height="24" [alt]="i | nameTierPipe"/>
        </td>
        <td data-title="PACA amount" [ngClass]="{'edit-opened': i==updateTierIndex}">
          <div [ngClass]="{'mb-3': i==updateTierIndex}">
            {{item.blpAmount| shiftDecimals:-18}} PACA
          </div>

          <mat-form-field appearance="fill" *ngIf="i==updateTierIndex">
            <mat-label>PACA amount</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="updateTierBlpAmount"
                   name="updateTierBlpAmount"
                   required>
          </mat-form-field>
        </td>
        <td data-title="Lockups" [ngClass]="{'edit-opened': i==updateTierIndex}">
          <div [ngClass]="{'mb-3': i==updateTierIndex}">
            {{item.timeLockups}} sec
          </div>

          <mat-form-field appearance="fill" *ngIf="i==updateTierIndex">
            <mat-label>Lockups</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="updateTierTimeLockups"
                   name="updateTierTimeLockups"
                   required>
          </mat-form-field>
        </td>
        <td>
          <button *ngIf="i!=updateTierIndex"
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="select(i)"
                  matTooltip="Edit Tier"
                  matTooltipPosition="above">
            <span class="icomoon-edit"></span>
          </button>

          <button *ngIf="i==updateTierIndex"
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="updateTierClick()"
                  matTooltip="Update Tier"
                  matTooltipPosition="above">
            <span class="icomoon-checkmark"></span>
          </button>
        </td>
      </tr>

      <!--<tr>
        <td data-title="Index">
          {{allTiers.length}}
        </td>
        <td data-title="Image">
          <img [src]="allTiers.length | imgTierPipe" height="24" />
        </td>
        <td class="tiers-table__editable" data-title="PACA amount">
          <mat-form-field appearance="fill">
            <mat-label>PACA amount</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="newTierBlpAmount"
                   name="newTierBlpAmount"
                   required>
          </mat-form-field>
        </td>
        <td class="tiers-table__editable" data-title="Lockups">
          <mat-form-field appearance="fill">
            <mat-label>Lockups</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="newTierTimeLockups"
                   name="newTierTimeLockups"
                   required>
          </mat-form-field>
        </td>
        <td>
          <button
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="addTierClick()"
                  matTooltip="Add Tier"
                  matTooltipPosition="above">
            <span class="icomoon-plus"></span>
          </button>
        </td>
      </tr>-->
      </tbody>
    </table>
  </section>
</main>
