export const environment = {
  production: false,
  polygon: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  eth: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  bsc: {
    blpAddress: '0x79B6bE1aa7C13AC017F4e93A52dD43127523A14D',
    dealCreatorAddress: '0x62F42E3D5Dc02fDC474a6b3Faaa11314e763B767',
    dealCollectWalletCreatorAddress: '0xF95F57B85a213f39e2cf34c97Dc47AC967302c55',
    lockerAddress: '0xB7F6fa2aAEaE6035300E9b812c82140E2EA1b75b',
    dealLockupsAddress: '0x71f03f038c7730c87B37Ed6164b0339fA67eb5b3',
    stakingPoolMasterAddress: '0xA6F04D2aa78fED4bD6d43F453F879D68C6A9a73D',
    merkleDistributorCreatorAddress: '0x8A8d165Be94991a3B28F20E8bE1eFDc8646De318',
    pancakeRouterAddress: '0x354bc1cFe9959b96beD409859a726263C6C56C81',
    tierCalculatorAddress: '0xB60b8CD68a558F36dD81EEf0B4C699Dc425C6fDC',
    stackingAddress: '0x4b3f7a8793b3ad883db8808edd2eb80fff7b2fe7',
    bonusAddress: '0x7309353f62f8B446f69d2A0eA926aB1B93664049',
    paymentTokens: [
      { name: "ETH", address: "0x5acc84a3e955bdd76467d3348077d003f00ffb97", icon: 'eth.svg'},
      { name: "USDC", address: "0x7d53daa28f63c86695a7ace113ecd0854bcfc6e2", icon: 'usdc.svg'}
    ],
    networkTypes: [
      { id: 1, label: 'ETH' },
      { id: 56, label: 'BSC' },
      { id: 137, label: 'Polygon' },
      { id: -100, label: 'Solana' },
      { id: -200, label: 'Tezos'},
      { id: 97, label: 'BSC Testnet' },
      { id: 42, label: 'Kovan' },
      { id: 80001, label: 'Polygon Testnet' },
      { id: 43114, label: 'Avalanche'},
      { id: 250, label: 'Fantom' },
      { id: 321, label: 'KCC' },
      { id: 322, label: 'KCC test'},
      { id: -9999100, label: 'Elrond' },
      { id: -9999101, label: 'Cardano' },
      { id: -9999102, label: 'Other' },
      { id: -9999103, label: 'Polkadot' },
      { id: -9999104, label: 'Cosmos' },
      { id: -9999105, label: 'Flow' },
      { id: -9999106, label: 'Near' },
      { id: 344435, label: 'Skale'},
      { id: 888, label: 'Wanchain'},
      { id: 1666600000, label: 'Harmony'},
      { id: 66, label: 'OKC'},
      { id: 42161, label: 'Arbitrum'},
      { id: -9999107, label: 'Sui'},
      { id: -9999108, label: 'Bitcoin'},
      { id: -9999109, label: 'Aptos'},
      { id: 10, label: 'Optimism'},
      { id: 8453, label: 'Base'},
      { id: 84532, label: 'Base Sepolia Testnet'},
      { id: 1284, label: 'Moonbeam'},
      { id: 592, label: 'Astar'},
      { id: 1890, label: 'Lightlink'},
      { id: -9999110, label: 'TON'},
      { id: 81457, label: 'Blast'},
      { id: -9999111, label: 'Injective'},
    ]
  },
  remoteServiceBaseUrl: "https://adminapi-dev-wt.gpkit.link",
  authServiceBaseUrl: "https://llama-bullauth.gpkit.link",
 
 
  applicantUrl: 'https://kycaid.com/en/applicants/',
  defaultChainId: 84532,
  supportedChains: [8453, 84532],
};
