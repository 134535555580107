import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgTierPipe"
})
export class ImgTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "/assets/images/tier/flat/none.svg";
    if (index == 0)
      return "/assets/images/tier/flat/copper.svg";
    if (index == 1)
      return "/assets/images/tier/flat/bronze.svg";
    if (index == 2)
      return "/assets/images/tier/flat/silver.svg";
    if (index == 3)
      return "/assets/images/tier/flat/gold.svg";
    if (index == 4)
      return "/assets/images/tier/flat/titanium.svg";
    if (index == 5)
      return "/assets/images/tier/flat/platinum.svg";
    return "/assets/images/tier/flat/none.svg";
  }
}
