import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nameTierPipe"
})
export class NameTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "None";
    if (index == 0)
      return "Copper Bull";
    if (index == 1)
      return "Bronze Bull";
    if (index == 2)
      return "Silver Bull";
    if (index == 3)
      return "Golden Bull";
    if (index == 4)
      return "Titanium Bull";
    if (index == 5)
      return "Platinum Bull";
    return "None";
  }
}
