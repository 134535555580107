import { Component, OnInit, Output } from '@angular/core';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import { Router } from '@angular/router';
import { Chart } from 'chart.js/auto'
import { AnalyticsServiceProxy, IAnalyticsDashboardDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { UserSessionProvider } from 'src/shared/user-session-provider';

@Component({
  selector: 'analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent extends ComponentBase implements OnInit {
  dashboard: IAnalyticsDashboardDTO;
  account: string = '';
  loading: boolean = false;
  lastSearch: string = null;
  searchString: string;
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  years: number[];
  selectedMonth: number;
  selectedYear: number;
  selectedComponent: 'new_user_registrations' | 'new_user_registrations_with_KYC' | 'referred_users' | 'tier_upgrade' | 'tier_downgrade' | 'new_tier_members' | 'tiers_lost' | 'new_deals' = 'new_user_registrations';
  public dealsChart: any;
  noOfDealsToDisplay: number = 5;
  countNoTierUsers = true;
  countNoVirtualTierUsers = true;

  constructor(private userSessionProvider: UserSessionProvider,
    private analyticsServiceProxy: AnalyticsServiceProxy,
    private router: Router) {
    super();

    let currentYear = new Date().getFullYear();
    this.years = [currentYear - 3, currentYear - 2, currentYear - 1, currentYear];
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.account = this.userSessionProvider.linkedWallet;
    this.fetchAnalyticsData();
  }
  getTierUserCount(tierIndex: number){
    if(tierIndex == -1){ // Users with no tier
      return this.dashboard.totalUsersCount - this.dashboard.tiers.reduce((acc, current) => acc + current.usersCount, 0);
    }

    let tier = this.dashboard.tiers.find(t => t.tierIndex == tierIndex);
    if(tier == null){
      return 0;
    }
    return tier.usersCount;
  }

  getVirtualTierUserCount(tierIndex: number){
    if(tierIndex == -1){ // Users with no virtual tier
      return this.dashboard.totalUsersCount - this.dashboard.bonusTiers.reduce((acc, current) => acc + current.usersCount, 0);
    }

    let tier = this.dashboard.bonusTiers.find(t => t.tierIndex == tierIndex);
    if(tier == null){
      return 0;
    }
    return tier.usersCount;
  }

  getAverageDealsParticipants(): string{
    if(!this.dashboard?.deals?.length){
      return '0';
    }
    let average = this.dashboard.deals.reduce((acc, current) => acc + current.participantsCount, 0) / this.dashboard.deals.length;
    return average.toFixed(1);
  }

  getTierUserCountPercentage(tierIndex: number){
    let userCountForTier = this.getTierUserCount(tierIndex);
    let result = 0;

    if(!this.countNoTierUsers){
      if(tierIndex == -1){
        return 0;
      }
      else{
        result = (userCountForTier * 100) / (this.dashboard.totalUsersCount - this.getTierUserCount(-1)); // exclude  non tiered users in total users count
      }
    }
    else{
      result = (userCountForTier * 100) / this.dashboard.totalUsersCount;
    }
    
    return result.toFixed(1)
  }

  getVirtualTierUserCountPercentage(tierIndex: number){
    let userCountForTier = this.getVirtualTierUserCount(tierIndex);
    let result = 0;

    if(!this.countNoVirtualTierUsers){
      if(tierIndex == -1){
        return 0;
      }
      else{
        result = (userCountForTier * 100) / (this.dashboard.totalUsersCount - this.getVirtualTierUserCount(-1)); // exclude  non virtual tiered users in total users count
      }
    }
    else{
      result = (userCountForTier * 100) / this.dashboard.totalUsersCount;
    }
    
    return result.toFixed(1)
  }

  filterChanged(){
    this.fetchAnalyticsData();
  }

  loadMoreDeals(){
    this.noOfDealsToDisplay+= 3;
  }

  fetchAnalyticsData(){
    this.loading = true;
    this.noOfDealsToDisplay = 3;

    this.analyticsServiceProxy.GetAnalyticsData(this.selectedMonth, this.selectedYear)
      .subscribe(result => {
        this.dashboard = result;
        this.loading = false;
        this.createChart();
      },
        error => {
          this.processServiceError(error);
        }, () => {
          this.loading = false;
        });
  }

  searchClick() {
    this.loading = true;
  }

  changeComponent(component: 'new_user_registrations' | 'new_user_registrations_with_KYC' | 'referred_users' | 'tier_upgrade' | 'tier_downgrade' | 'new_tier_members' | 'tiers_lost' | 'new_deals' = 'new_user_registrations'){
    this.selectedComponent = component;
  }

  createChart(){
    if(this.dealsChart){
      console.log(this.dashboard.deals.slice(0, 3).map(d => d.participantsCount));
      this.dealsChart.data.labels.pop();
      this.dealsChart.data.datasets.forEach((dataset: any) => {
          dataset.data.pop();
      });  

      this.dealsChart.data.labels = this.dashboard.deals.slice(0, 3).map(d => d.dealName);
      this.dealsChart.data.datasets = [
        {
          data: this.dashboard.deals.slice(0, 3).map(d => d.participantsCount),
          backgroundColor: ['darkblue', 'purple', 'green'],
          barThickness: 10,
          borderRadius: 2
        }
      ];
      this.dealsChart.update();
    }
    else{
      this.dealsChart = new Chart("deals-chart", {
        type: 'bar',
        data: {
          labels: this.dashboard.deals.slice(0, 3).map(d => d.dealName), 
           datasets: [
            {
              data: this.dashboard.deals.slice(0, 3).map(d => d.participantsCount),
              backgroundColor: ['darkblue', 'purple', 'green'],
              barThickness: 10,
              borderRadius: 2
            }  
          ],
        },
        options: {
          aspectRatio:2.5,
          plugins:{
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }, 
          scales: {
            x: {
              display: false
            },
            y: {
              display: false
            }
          }
        }
        
      });
    }

  }

  removeData(data: any) {
    
}

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.router.navigate(["/login"]);
    }
    else
      this.showErrorModal(error.message || JSON.parse(error.response).message);
  }
  
}
