<div class="change-address-page">
  <div class="change-address-filter-bar">
    <div class="search">
      <mat-form-field appearance="fill" class="icon-form-field">
        <i class="icon icomoon-search text-muted"></i>
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchText" name="searchText" />
      </mat-form-field>
    </div>

    <div class="action-buttons">
      <button type="button" mat-raised-button color="primary" (click)="searchWallet()">Search <span class="rainbow-border"></span></button>

      <!--<button mat-icon-button class="btn-icon btn-download">
        <i class="icon icomoon-down"></i>
      </button>-->

      <div class="change-address-switch-wrapper">
        <div class="change-address-switch-text">Show All</div>
        <mat-slide-toggle name="isVisible" [(ngModel)]="isVisible" (change)="filterWallets()"></mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="change-address-table">
    <table class="table table-responsive">
      <thead>
        <tr>
          <th class="__oldwallet-col">Old Wallet</th>
          <th class="__newwallet-col">New Wallet</th>
          <th class="__kyc-col">KYC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let wallet of pageSlice">
          <td class="oldwallet-row td-responsive td-responsive-full" data-title="OldWallet">
            {{wallet.fromAddress}}
          </td>
          <td class="newwallet-row td-responsive td-responsive-full" data-title="NewWallet">
            {{wallet.toAddress}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="KYC">
            <span class="__kyc-status" (click)="transferTier(wallet)">
              <i *ngIf="wallet.kycConfirmed" class="icomoon-checked"></i>
              <i *ngIf="!wallet.kycConfirmed" class="icomoon-close-fill"></i>
              {{wallet.kycConfirmed ? 'Passed' : 'No'}}
            </span>
          </td>
          <td class="td-responsive td-responsive-full" data-title="Wallet">
            <div class="d-flex align-items-center justify-content-center">
              <button type="button" mat-raised-button color="primary" [disabled]="wallet.status === 3" (click)="transferTier(wallet)">
                {{wallet.status === 3 ? 'Tier Transferred' : 'Transfer Tier'}}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="change-address-pagination">
    <mat-paginator
      [pageSize]="pageSize"
      [length]="wallets.length"
      [pageSizeOptions]="[6, 10, 15]"
      showFirstLastButtons
      (page)="onPageChange($event)"
      class="wallets-paginator">
    </mat-paginator>
  </div>
</div>
