import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ReferrersWithSum } from 'src/models/referral.model';

@Component({
  selector: 'app-ref-extantion-panel',
  templateUrl: './ref-extantion-panel.component.html',
  styleUrls: ['./ref-extantion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefExtantionPanelComponent {
  @Input() ref!: ReferrersWithSum;
  @Input() searchString!: string;
}
