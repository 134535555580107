import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nameTierPipe"
})
export class NameTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "None";
    if (index == 0)
      return "Baby Paca";
    if (index == 1)
      return "Little Paca";
    if (index == 2)
      return "Chief Paca";
    if (index == 3)
      return "Elder Paca";
    if (index == 4)
      return "Alpha Paca";
    return "None";
  }
}
