import {Component, OnInit} from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { EquityDealDTO, EquityDealServiceProxy, IEquityDealDTO } from '../../service-proxies/service-proxies';
import { QuillModules, QuillEditorComponent } from 'ngx-quill';
import { Web3Service } from '../../shared/web3-service';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";
@Component({
  selector: 'equity-deal',
  templateUrl: './equity-deal.component.html',
  styleUrls: ['./equity-deal.component.scss']
})
export class EquityDealComponent extends ComponentBase implements OnInit  {
  public Editor: any = Editor;
  public config: CKEditor5.Config = {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    link: {
      defaultProtocol: 'http://'
    },
    mediaEmbed: {
      previewsInData: true
    }
  }
  constructor(
    private web3: Web3Service,
    private dealService: EquityDealServiceProxy,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  quillStyles = {'min-height': '100px'};
  quillConfig: QuillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['clean'],
      ['image'],
      ['video'],
    ],
  };

  waiting: boolean = false;
  deal: IEquityDealDTO = new EquityDealDTO();

  public get editing(): boolean {
    return this.deal.id != 0;
  }

  addHttpsToLinks(editor: QuillEditorComponent): any {
    const links = editor.quillEditor.root.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      if (!links[i].attributes.href.value.startsWith('https:')) {
        if (links[i].attributes.href.value.startsWith('http://')) {
          links[i].href = links[i].attributes.href.value;
        }else {
          links[i].href = 'https://' + links[i].attributes.href.value;
        }
      }
    }
  }

  async ngOnInit() {
    this.deal.id = 0;
    this.deal.chainId = this.web3.chainIdNumber;

    this.activatedRoute.queryParams
      .subscribe(params => {
          if (params.id) {
            this.deal.id = params.id;
            this.dealService.get(params.id).subscribe(result => {
                this.deal = result;
              },
              error => {
                this.showErrorModal(JSON.parse(error.response).message);
              });
          }
        }
      );
  }

  async submitClick(): Promise<void> {
    this.waiting = true;
    if (this.deal.wireDeadline instanceof Date){
      this.deal.wireDeadline.setMinutes(this.deal.wireDeadline.getMinutes() - this.deal.wireDeadline.getTimezoneOffset());
    }
    if (this.deal.deadline instanceof Date){
      this.deal.deadline.setMinutes(this.deal.deadline.getMinutes() - this.deal.deadline.getTimezoneOffset());
    }
    if (this.editing) {
      this.dealService.update(this.deal)
        .subscribe(
          result => {
            this.waiting = false;
            this.showInfoModal('Saved');
            this.router.navigate(['/equity-deals']);
          },
          error => {
            this.waiting = false;
            console.log(error);
            this.showErrorModal(error.error?.title || error.message);
          }
        );
    }
    else {
      this.dealService.create(this.deal)
        .subscribe(
          result => {
            this.waiting = false;
            this.showInfoModal('Saved');
            this.router.navigate(['/equity-deals']);
          },
          error => {
            this.waiting = false;
            console.log(error);
            this.showErrorModal(error.error?.title || error.message);
          }
        );
    }

  }
}
