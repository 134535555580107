<main class="dashboard-main">
  <section class="dashboard-section section-alert p-40">
    <div class="alert alert-warning d-flex align-items-center" role="alert">
      <div class="alert-icon icomoon-plumpy-info"></div>
      <div class="alert-heading">
        To join the deals you need to pass KYC verification
      </div>
      <div class="alert-button ms-auto">
        <button class="btn btn-primary btn-icon btn-icon-right">
          KYC for BullStarter Projects
          <i class="icon icomoon-plumpy-user"></i>
        </button>
      </div>
    </div>
  </section>

  <section class="dashboard-section">
    <div class="row gx-4">
      <div class="col-lg-4">
        <div class="white-card">
          <div
            class="
              white-card__header
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <div class="label">LLAMA Tokens</div>
            <div class="text-muted">1 LLAMA = 0.065 ETH</div>
          </div>
          <div class="white-card__content">
            <div class="d-flex flex-wrap align-items-center pb-32">
              <div class="value me-3">200 LLAMA</div>
              <div class="text-primary fw-600">13 ETH</div>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-outline-secondary btn-icon btn-icon-left">
                <i class="icon icomoon-plumpy-lock"></i> Lock-in
              </button>
              <button class="btn btn-outline-secondary btn-icon btn-icon-left">
                <i class="icon icomoon-plumpy-key"></i> Unlock
              </button>
            </div>
          </div>
          <div
            class="
              white-card__footer
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <div class="label my-2">Your Wallet</div>
            <div class="wallet-address my-2">
              <span class="token-address"
                >0x67f244f1bf479c03714da9c621285943f3cbcdc6</span
              >
              <button class="copy-icon icomoon-plumpy-copy"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="row gx-4 mb-20">
          <div class="col-lg-6">
            <div class="white-card">
              <div class="white-card__header">
                <div class="label">Locked-in Tokens</div>
              </div>
              <div class="white-card__content pb-32">
                <div class="value">16 LLAMA</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="white-card">
              <div class="white-card__header">
                <div class="label">Current Tier</div>
              </div>
              <div class="white-card__content pb-32">
                <div class="bull-value d-flex align-items-center">
                  <div class="bull-icon logo-md contain-img me-3">
                    <img src="../../assets/images/tier/none.png" alt="" />
                  </div>
                  <div class="value fw-normal me-3">None</div>
                  <div class="info-tooltip icomoon-plumpy-info"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="white-card">
          <div class="white-card__header">
            <div class="label">Tier Progress</div>
          </div>
          <div class="white-card__content">
            <div class="progress-bull-total">
              <div class="progress progress-primary progress-sm">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div
                class="
                  progress-legend progress-legend-bull
                  d-flex
                  justify-content-between
                "
              >
                <div class="progress-legend__bull">
                  <div class="legend-bull-icon logo-sm">
                    <img
                      src="../../assets/images/tier/bronze-bull.png"
                      alt=""
                    />
                  </div>
                  <div class="legend-bull-title">Bronze Bull</div>
                  <div class="legend-bull-value text-muted">500 LLAMA</div>
                </div>
                <div class="progress-legend__bull">
                  <div class="legend-bull-icon logo-sm">
                    <img
                      src="../../assets/images/tier/silver-bull.png"
                      alt=""
                    />
                  </div>
                  <div class="legend-bull-title">Silver Bull</div>
                  <div class="legend-bull-value text-muted">10,000 LLAMA</div>
                </div>
                <div class="progress-legend__bull">
                  <div class="legend-bull-icon logo-sm">
                    <img
                      src="../../assets/images/tier/golden-bull.png"
                      alt=""
                    />
                  </div>
                  <div class="legend-bull-title">Golden Bull</div>
                  <div class="legend-bull-value text-muted">100,000 LLAMA</div>
                </div>
                <div class="progress-legend__bull">
                  <div class="legend-bull-icon logo-sm">
                    <img
                      src="../../assets/images/tier/titanium-bull.png"
                      alt=""
                    />
                  </div>
                  <div class="legend-bull-title">Titanium Bull</div>
                  <div class="legend-bull-value text-muted">200,000 LLAMA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section pt-32">
    <div class="row">
      <div class="col-lg-6 gx-4">
        <div class="white-card">
          <div class="white-card__header d-flex align-items-center">
            <div class="info-tooltip icomoon-plumpy-info me-2"></div>
            <div class="label">Penalties</div>
          </div>
          <div class="white-card__content p-0">
            <table class="table penalties-table">
              <tr>
                <th>Less than 10 days</th>
                <td>30%</td>
              </tr>
              <tr>
                <th>Less than 20 days</th>
                <td>25%</td>
              </tr>
              <tr>
                <th>Less than 30 days</th>
                <td>20%</td>
              </tr>
              <tr>
                <th>Less than 60 days</th>
                <td>10%</td>
              </tr>
              <tr>
                <th>Less than 90 days</th>
                <td>5%</td>
              </tr>
              <tr>
                <th>After 90 days</th>
                <td>0%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white-card">
          <div class="white-card__header">
            <div class="label">Your stats</div>
          </div>
          <div class="white-card__content">
            <div class="text-muted small-label">Current Penalty</div>
            <div class="value">0%</div>
          </div>
          <div class="white-card__footer">
            <div class="text-muted small-label">Last Deposit</div>
            <div class="value">0 days ago</div>

            <div class="graph mt-4">GRAPH HERE</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
