import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'dlg-snapshot',
  templateUrl: './dlg-snapshot.component.html',
  styleUrls: ['./dlg-snapshot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgSnapshotComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DlgSnapshotComponent>
  ) { }

  locker: string;
  stakingPools: string;
  farmingPool: string;
  includeInactive: boolean;
  includeWithoutKYC: boolean;

  ngOnInit(): void { 
    this.locker = environment.bsc.lockerAddress;
    this.stakingPools = environment.bsc.stackingAddress
  }

  get isEmpty() {
    return !this.locker && !this.stakingPools && !this.farmingPool;
  }

  save() {
    this.dialogRef.close({
      locker: this.locker,
      stakingPools: this.stakingPools?.split('\n'),
      farmingPool: this.farmingPool,
      includeInactive: this.includeInactive,
      includeWithoutKYC: this.includeInactive ? this.includeWithoutKYC : false
    });
  }
}
