<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
</div>
<div class="modal-body alert-template">
  <mat-icon *ngIf='icon'>{{ icon }}</mat-icon>

  <div *ngIf="message" class="text-center small-text mb-4">{{ message }}</div>

  <div *ngIf="innerHTML" [innerHTML]="innerHTML"></div>

  <button class="btn btn-primary w-100"
          (click)='onContinue()'>
    {{ buttonOkLabel || 'OK' }}
    <span class="rainbow-border"></span>
  </button>
</div>



