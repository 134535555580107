<section class="__users-table p-40">
  <div class="card p-4 mb-40">
    <div class="form-group-inline wrap-in-mob">
      <mat-form-field appearance="fill" class="icon-form-field">
        <i class="icon icomoon-search text-muted"></i>
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchString"  name="searchString">
      </mat-form-field>

      <button type="button"
              mat-raised-button
              color="primary"
              (click)="searchClick()">
        Search
        <span class="rainbow-border"></span>
      </button>
    </div>
  </div>

  <table class="table table-responsive" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr>
        <th class="__date-col" mat-sort-header="date">Registration</th>
        <th class="__email-col" mat-sort-header="email">
          <span matTooltip="Sort by e-mail" matTooltipPosition="above">
            E-mail
          </span>
        </th>
        <th class="__kys-col">KYC</th>
        <th class="__wallet-col">Wallet</th>
      </tr>
    </thead>
    <tbody>
      <!--creationDateTime!: moment.Moment;
      email!: string | undefined;
      ethAddress!: string | undefined;
      sumAndSupApplicantId!: string | undefined;
      needToLoadDocuments!: boolean;
      kycConfirmed!: boolean;-->
      <tr *ngFor="let item of users; index as i">
        <td class="__date-col td-responsive td-responsive-full" data-title="Registration">
          {{item.creationDateTime}}
        </td>
        <td class="td-responsive td-responsive-full" data-title="E-mail">
          {{item.email}}
        </td>
        <td class="td-responsive td-responsive-full" data-title="KYC">
          <span *ngIf="item.kycConfirmed" class="__kys-status --passed">
            <i class="icomoon-checked"></i>
            Passed
          </span>
          <span *ngIf="!item.kycConfirmed" class="__kys-status">
            <i class="icomoon-close-fill"></i>
            No
          </span>
        </td>
        <td class="td-responsive td-responsive-full" data-title="Wallet">
          <div class="d-flex align-items-center justify-content-between">
            <div class="__address">{{item.ethAddress}}</div>

            <a mat-icon-button
               class="ms-3"
               matTooltip="Address Book"
               matTooltipPosition="above"
               (click)="navigateToUserDetails(item.ethAddress)">
              <span class="icomoon-address-book"></span>
            </a>
            <a [href]="getApplicantURL(item.sumAndSupApplicantId)"
               mat-icon-button
               matTooltip="KYC link"
               matTooltipPosition="above"
               target="_blank"
               *ngIf="item.sumAndSupApplicantId">
              <span class="icomoon-kys"></span>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</section>

