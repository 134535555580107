<div class="card mb-4">
    <div class="row p-4">
      <div class="col">
        <mat-form-field class="white-label">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label >Search</mat-label>
            <input placeholder="wallet address"  matInput [(ngModel)]="searchValue" value="">
          </mat-form-field>
      </div>
      <div class="col-3">
        <button type="button" mat-raised-button class="bg-success" [disabled]="isLoading" (click)="fetchDealsPayment()">Search</button>
        <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base m-3"><img (click)="saveData()" src="../../assets/svg/download-solid.svg"/></button>
      </div>
    </div>
  </div>

<div class="card">
    <form [formGroup]="filterForm" class="p-4">
        <p >Filter by:</p>
        <div class="row p-3">
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Tier</mat-label>
                    <mat-select formControlName="tier">
                        <mat-option [value]="-2">Show All</mat-option>
                        <mat-option *ngFor="let tier of tiers" [value]="tier.index">
                            {{tier.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="dateFrom" placeholder="Start date">
                        <input matEndDate formControlName="dateTo" placeholder="End date">
                    </mat-date-range-input>

                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Deal</mat-label>
                    <mat-select formControlName="deal">
                        <mat-option [value]="'any'">Show All</mat-option>
                        <mat-option *ngFor="let deal of deals" [value]="deal.dealName">
                            {{deal.dealName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <button (click)="fetchDealsPayment()" mat-raised-button class="bg-success w-100" [disabled]="isLoading">
                    <mat-icon>search</mat-icon>
                    Apply Filter
                </button>
            </div>
        </div>
    </form>
</div>

<div class="card mt-3">
    <table class="table table-responsive" matSort (matSortChange)="sortData($event)" [matSortActive]="sortByColumn"
        [matSortDirection]="sortDirection">
        <thead>
            <tr>
                <th mat-sort-header="registration">Tier</th>
                <th>Paid</th>
                <th>Payment Time</th>
                <th>Emission Address</th>
            </tr>
        </thead>
        <tbody [ngClass]="{'opacity-50': isLoading}">
            <tr *ngFor="let payment of dealPayments">
                <td>{{payment.tier | nameTierPipe}}</td>
                <td><img class="side-icon" [src]="payment.paymentTokenSymbol| imgTokenPipe"> {{payment.amountPaid}} {{payment.paymentTokenName}} </td>
                <td>{{ formatDate(payment.paymentTime) }} <br> {{ formatTimeToUTC(payment.paymentTime)}} </td>
                <td>
                    <span>{{payment.emissionAddress}}</span>
                    <a mat-icon-button class="m-3" matTooltip="Address Book" matTooltipPosition="above"
                        (click)="navigateToUserDetails(payment.emissionAddress)">
                        <span class="icomoon-address-book"></span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="card mt-3">
    <mat-paginator [length]="totalCount"
                       [pageIndex]="page"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="pageChanged($event)"
                       aria-label="Select page">
        </mat-paginator>
</div>